import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormHelperText, InputAdornment } from "@mui/material";
import Calendar from "assets/icon/ic_calendar.svg";
import { useEffect } from "react";
import moment from "moment";

export default function MaterialUIPickers({ props }: any) {
  const [value, setValue] = React.useState<any | null>("");
  const [valid, setValid] = React.useState("");

  const handleChange = (newValue: any | null) => {
    props.onChange(moment(newValue._d).format("YYYY-MM-DD"));
    setValue(newValue);
  };
  useEffect(() => {
    setValue(props.value);
    setValid(props.valid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.valid]);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={4}>
        <MobileDatePicker
          className="input-datepicker"
          inputFormat="DD/MM/YYYY"
          value={value || ""}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              placeholder={props.placeholder}
              {...props}
              size="small"
              sx={{
                "& fieldset": {
                  border: "none",
                  minHeight: 0,
                },
                boxShadow: 4,
                borderRadius: 1,
                minHeight: 0,
                height: "40px",
              }}
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* <AccountCircle /> */}
                    <img src={Calendar} alt="details" width="80%" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>
      {valid === "valid" ? (
        <FormHelperText style={{ color: "red" }}>
          {"กรุณาระบุวันที่"}
        </FormHelperText>
      ) : (
        <></>
      )}
    </LocalizationProvider>
  );
}
