import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public search(page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/banner/all?page=${page}&pageSize=${pageSize}`);
  }
  public getById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/banner/${id}`);
  }
  public create(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_banner");
    const formData = new FormData();
    formData.append("bannerFile", data.file);
    return httpService.post<Response>(`admin/banner`, formData);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_banner");
    const formData = new FormData();
    formData.append("bannerFile", data.file);
    return httpService.patch<Response>(`admin/banner/${data.id}`, formData);
  }
  public updateActive(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_banner");
    return httpService.patch<Response>(`admin/banner/active/${data.id}`, {
      isActive: data.isActive,
    });
  }
  public delete(id: string): Promise<AxiosResponse<Response>> {
    logService.create("manage_banner");
    return httpService.delete<Response>(`admin/banner/${id}`);
  }
}

export const bannerService = new Services();
