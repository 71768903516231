import { Grid, InputAdornment } from "@mui/material";
import { useState } from "react";
import { Paper } from "@mui/material";
import { FormControl } from "@mui/material";
import { Button } from "@mui/material";

import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "components/FormInput";
import { authService } from "services/authentication/auth.service";

import EmailIcon from "@mui/icons-material/Email";
import Logo from "assets/logo/ic_bg_small@2x.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Modal from "components/Dialog";
import { IModal } from "interfaces/utils/utils.interface";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
interface State {
  email: string;
}

const forgotpasswordSchema = object({
  email: string().min(1, "กรุณาระบุอีเมล").email("รูปแบบอีเมลไม่ถูกต้อง"),
});
type IForgotPassword = TypeOf<typeof forgotpasswordSchema>;

export default function Forgotpassword() {
  const [values] = useState<State>({
    email: "",
  });
  const [modal, setModal] = useState<IModal>(defaultModal);

  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: IForgotPassword = {
    email: values.email,
  };
  const methods = useForm<IForgotPassword>({
    resolver: zodResolver(forgotpasswordSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<IForgotPassword> = async (values: IForgotPassword) => {
    setLoading(true);
    await authService
      .forgotPassword(values.email)
      .then(async (item) => {
        setLoading(false);
        setModal({
          open: true,
          message: "ระบบได้ทำการส่งลิงค์เพื่อตั้งรหัสผ่านใหม่ไปยังอีเมลของคุณเรียบร้อยแล้ว",
          status: "success",
          navigateTo: "/login",
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 400) {
          setModal({
            open: true,
            message: "เกิดข้อผิดพลาดระบบทำการส่งลิงค์เพื่อตั้งรหัสผ่านใหม่ไปยังอีเมลของคุณไม่สำเร็จ",
            status: "error",
            navigateTo: "/login",
          });
        } else {
          setModal({
            open: true,
            message: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
            status: "error",
            navigateTo: "/login",
          });
        }
      });
  };

  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          style={styles.paperContainer}
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={5}
            sm={12}
            md={5}
            lg={6}
            sx={{
              height: "100vh",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100vh",
              }}
            >
              <img style={{ paddingLeft: "2px", paddingBottom: "5px" }} src={Logo} alt="logo" />
              <div style={styles.txtLeftPadding}>Central Metals (Thailand) Ltd.</div>
            </Grid>
          </Grid>
          <Grid container alignContent={"center"} justifyContent={"center"} item xs={12} sm={12} md={7} lg={6} sx={{ pr: 7, pl: 1, height: "100vh" }}>
            <Grid item xs={10} md={10}>
              <Paper elevation={4} square sx={{ height: "70vh", minHeight: "500px" }}>
                <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      py: 5,
                    }}
                    justifyContent="center"
                  >
                    <Grid item xs={8}>
                      <Grid container item sx={{ pb: 10, pt: 5 }}>
                        <div
                          style={{
                            fontSize: "37px",
                          }}
                        >
                          <Link to="/">
                            <Button size="small" variant="contained" className="nav-back-btn" sx={styles.btnBack}>
                              <ArrowBackIcon color="primary" sx={{ fontSize: 22, fontWeight: "bold" }}></ArrowBackIcon>
                            </Button>
                          </Link>
                          <label>ลืมรหัสผ่าน</label>
                        </div>
                      </Grid>
                      <Grid item pb={2}>
                        <div style={styles.txtDescription}>กรุณากรอกอีเมลของคุณระบบจะทำการส่งลิงค์เพื่อตั้งรหัสผ่านใหม่ไปยังอีเมลของคุณ</div>
                      </Grid>
                      <Grid item pb={5}>
                        <FormControl fullWidth variant="outlined">
                          <FormInput
                            variant="standard"
                            placeholder="อีเมล"
                            type="email"
                            name="email"
                            sx={{ mb: 5 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item container justifyContent="center">
                        <Button style={styles.btnConfirm} className="btn-grad" variant="contained" color="primary" type="submit">
                          ตกลง
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
