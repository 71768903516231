import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";

export interface CounterState {
  showBtnBack: boolean;
  backToPath: string;
  title: string;
  loading: boolean;
  cmt_fullname: string;
  cmt_token: string;
  currentUser: string;
  isRootAdmin: boolean;
  isLogged: boolean;
}

const initialState: CounterState = {
  showBtnBack: false,
  backToPath: "",
  title: "",
  loading: false,
  cmt_fullname: "",
  cmt_token: "",
  isRootAdmin: localStorage.getItem("cmt_admin") ? true : false,
  currentUser: localStorage.getItem("cmt_fullname") || "",
  isLogged: localStorage.getItem("cmt_token") ? true : false,
};

export const appStore = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTitleWithBack: (state, action: any) => {
      state.showBtnBack = true;
      state.title = action.payload.title;
      state.backToPath = action.payload.path;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.showBtnBack = false;
      state.backToPath = "";
      state.title = action.payload;
    },
    setUser: (state, action: any) => {
      const payload = action.payload;
      const fullname = `${payload.firstname} ${payload.lastname}`;
      state.cmt_fullname = fullname;
      state.cmt_token = payload.token;
      localStorage.setItem("cmt_token", payload.token);
      localStorage.setItem("cmt_fullname", fullname);
      state.isRootAdmin = payload.adminRoles[0].id === 1 ? true : false;
      if (payload.adminRoles[0].id === 1) {
        localStorage.setItem("cmt_admin", payload.adminRoles[0].id);
      }
    },
    setProfile: (state, action: any) => {
      const payload = action.payload;
      if (payload.message === "error") {
        state.isRootAdmin = false;
      } else {
        state.isRootAdmin = payload.adminRoles[0].id === 1 ? true : false;
        if (payload.adminRoles[0].id === 1) {
          localStorage.setItem("cmt_admin", payload.adminRoles[0].id);
        }
        //set for navbar fullname
        const fullname = `${payload.firstname} ${payload.lastname}`;
        state.cmt_fullname = fullname;
        state.currentUser = fullname;
        localStorage.setItem("cmt_fullname", fullname);
      }
    },
  },
});

export const { setTitle, setUser, setProfile, setTitleWithBack } = appStore.actions;

export const selectTitle = (state: RootState) => state.user.title;
export const selectShowBtnBack = (state: RootState) => state.user.showBtnBack;
export const selectBackToPath = (state: RootState) => state.user.backToPath;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectRootAdmin = (state: RootState) => state.user.isRootAdmin;
export const selectIsLogged = (state: RootState) => state.user.isLogged;
export default appStore.reducer;
