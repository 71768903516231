import { FC } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root.Mui-disabled > input:disabled,textarea:disabled": {
    WebkitTextFillColor: "#000",
    "& fieldset": {
      border: 0,
    },
  },
  "& label.Mui-focused": {
    // color: "#5e5b5d",
    fontWeight: 400,
  },
  "& .MuiInputBase-input": {
    // borderColor: "#c8d0d4",
  },
  "& .MuiInput-underline:after": {
    // border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d32f2f",
      },
    },
    "& fieldset": {
      // borderColor: "#c8d0d4",
      borderRadius: 5,
      // border: 0,
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      // border: "2px solid #c8d0d4",
    },
    "&.Mui-focused fieldset": {
      // border: "2px solid #2186b2",
    },
  },
});

// type FormInputProps = {
//   name: string;
// } & TextFieldProps;

const FormInput: FC<any> = (props: any) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue=""
      render={({ field }) => (
        <CssTextField
          inputProps={{
            style: {
              height: props.multiline ? "" : "30px",
              color: "red !important",
            },
          }}
          {...field}
          {...props}
          disabled={props.disabled}
          sx={{ mb: "1.5rem", bgcolor: props.disabled ? "#f5f6fa" : "" }}
          error={!!errors[props.name]}
          helperText={errors[props.name] ? (errors[props.name]?.message as unknown as string) : ""}
        />
      )}
    />
  );
};

export default FormInput;
