import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/machine-control/all?page=${page}&pageSize=${pageSize}`);
  }

  public getById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/machine-control/${id}`);
  }
  public create(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_machine_control");
    const formData = new FormData();
    formData.append("imgFile", data.imgFile);
    formData.append("videoFile", data.videoFile);
    formData.append("fileType", data.fileType);
    formData.append("nameTh", data.nameTh);
    formData.append("nameEn", data.nameEn);
    formData.append("detailTh", data.detailTh);
    formData.append("detailEn", data.detailEn);
    formData.append("isActive", data.isActive);
    return httpService.post<Response>(`admin/machine-control`, formData);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_machine_control");
    const formData = new FormData();
    formData.append("imgFile", data.imgFile);
    formData.append("videoFile", data.videoFile);
    formData.append("fileType", data.fileType);
    formData.append("nameTh", data.nameTh);
    formData.append("nameEn", data.nameEn);
    formData.append("detailTh", data.detailTh);
    formData.append("detailEn", data.detailEn);
    formData.append("isActive", data.isActive);
    return httpService.patch<Response>(`admin/machine-control/${data.id}`, formData);
  }
  public updateActive(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_machine_control");
    return httpService.patch<Response>(`admin/machine-control/active/${data.id}`, {
      isActive: data.isActive,
    });
  }
  public delete(id: string): Promise<AxiosResponse<Response>> {
    logService.create("manage_machine_control");
    return httpService.delete<Response>(`admin/machine-control/${id}`);
  }
}

export const machineService = new Services();
