import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setTitleWithBack } from "store/reducer/appReducer";
import { Link, useLocation, useParams } from "react-router-dom";
import { errorMsg } from "helpers/helper";
import Checkbox from "components/Checkbox";
import { Button, Grid, Typography } from "@mui/material";

import FormInput from "components/FormInput";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { IModal } from "interfaces/utils/utils.interface";
import { adminService } from "services/admin/admin.service";
import { styles } from "assets/styles";
import { defaultModal, editSuccess, saveSuccess, somethingWrong } from "helpers/utility";
import { AdminPosition, AdminSystemUses } from "interfaces/admin/admin.interface";
const Schema = object({
  name: string().min(1, "กรุณาระบุตำแหน่ง"),
});
type IRole = TypeOf<typeof Schema>;

export default function UserManagement() {
  const backToPath = "/admin/role";
  const dispatch = useDispatch();
  const location = useLocation();
  const isCreate = location.pathname.search("create") !== -1;
  const params = useParams();

  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  let defaultPermission: any = [];
  const [permission, setPermission] = useState<any>([]);

  const [validatePermission, setValidatePermission] = useState(false);
  const defaultValues: IRole = {
    name: "",
  };
  const methods = useForm<IRole>({
    resolver: zodResolver(Schema),
    defaultValues,
  });

  const setMethodValues = (value: any) => {
    methods.setValue("name", value.name);
  };

  const getDefaultSystemUses = async () => {
    return await adminService.getAllSystemUses().then((item: any) => {
      const data = item.data.data ?? item.data;
      const entity = data.map((item: AdminSystemUses) => {
        return {
          id: item.id,
          name: item.name,
          value: false,
        };
      });
      defaultPermission = entity;
      if (isCreate) setPermission(defaultPermission);
    });
  };
  const getById = async () => {
    await getDefaultSystemUses();
    await adminService.getPositionById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      data.adminSystemUses?.map(async (item: any, index: number) => {
        const permissionIdx = defaultPermission.findIndex((w: any) => item.id === w.id);
        defaultPermission[permissionIdx].value = true;
      });
      setPermission(defaultPermission);
      setMethodValues(data);
    });
  };
  useEffect(() => {
    if (isCreate) {
      dispatch(setTitleWithBack({ title: "เพิ่มตำแหน่งและสิทธิ์การใช้งานระบบ", path: backToPath }));

      getDefaultSystemUses();
    } else {
      dispatch(setTitleWithBack({ title: "แก้ไขตำแหน่งและสิทธิ์การใช้งานระบบ", path: backToPath }));

      getById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleValid = () => {
    const flag = permission.every((item: any) => item.value === false);
    setValidatePermission(flag);
  };
  const onSubmitHandler: SubmitHandler<IRole> = async (values: IRole) => {
    if (validatePermission) return;
    setLoading(true);
    let adminPosition: any = [];
    permission.map((item: any, index: number) => {
      if (item.value === true) adminPosition.push(item.id);
      return item;
    });
    const entity: AdminPosition = {
      id: parseInt(params.id || ""),
      name: values.name,
      adminSystemUses: adminPosition,
    };

    if (isCreate) {
      await adminService
        .createPosition(entity)
        .then(() => {
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
      setLoading(false);
    } else {
      await adminService
        .updatePosition(entity)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
      setLoading(false);
    }
  };

  const form = (
    <>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={3} pt={5}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">
              <span className="req">*</span>ตำแหน่ง
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormInput fullWidth size="small" name="name" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={3}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">
              <span className="req">*</span>สิทธิ์การใช้งาน
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Checkbox
              name="สิทธิ์การใช้งาน"
              list={permission}
              onChange={setPermission}
              validate={validatePermission}
              handleValid={setValidatePermission}
            ></Checkbox>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" my={2}>
          <Link to={backToPath} style={{ textDecoration: "none" }}>
            <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
              ยกเลิก
            </Button>
          </Link>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit" onClick={() => handleValid()}>
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
