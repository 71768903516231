import { Link, useLocation } from "react-router-dom";
import { setTitleWithBack } from "store/reducer/appReducer";
import { useDispatch } from "react-redux";
import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { saveSuccess, editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import FileInput from "components/FileInput";
import Switch from "components/Switch";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import defaultImg from "assets/image/ic_image.png";
import FormInput from "components/FormInput";
import { Box } from "@mui/system";
import { logoService } from "services/user/logo.service";
import { errorMsg } from "helpers/helper";
const Schema = object({
  file: string().min(1, "กรุณาเลือกไฟล์"),
  name: string().min(1, "กรุณาระบุหัวข้อภาษาไทย"),
});
type ISchema = TypeOf<typeof Schema>;
export default function LogoForm() {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState<any>();
  const [file, setFile] = useState<any>();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = { file: "", name: "" };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const location = useLocation();
  const backToPath = "/logo";
  const isCreate = location.pathname.search("create") !== -1;
  const params = useParams();
  const getById = async () => {
    await logoService.getById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      setStatus(data.isActive);

      setFileName(data.logoImg);
      setImage(data.logoImg);
      methods.setValue("file", data.logoImg);

      methods.setValue("name", data.name);
    });
  };
  useEffect(() => {
    if (isCreate) dispatch(setTitleWithBack({ title: "เพิ่มโลโก้ลูกค้า", path: backToPath }));
    else {
      getById();
      dispatch(setTitleWithBack({ title: "แก้ไขโลโก้ลูกค้า", path: backToPath }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    const data = {
      id: parseInt(params.id || ""),
      name: values.name,
      logoFile: file,
      isActive: status,
    };
    setLoading(true);
    if (isCreate) {
      await logoService
        .create(data)
        .then(() => {
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file size is too large") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 10MB",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      await logoService
        .update(data)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file size is too large") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 10MB",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    }
    setLoading(false);
  };
  const handleFile = (item: any) => {
    methods.clearErrors();
    if (item) {
      methods.setValue("file", item.name);
      setFileName(item.name);
      setFile(item);
      setImage(URL.createObjectURL(item));
    } else {
      methods.setValue("file", "");
      setFileName("");
      setFile(null);
      setImage(undefined);
    }
  };
  const handleActive = (isActive: boolean) => {
    setStatus(isActive);
    if (!isCreate) active(isActive, params.id);
  };
  const active = async (status: boolean, id: any) => {
    const data = {
      id: id,
      isActive: status,
    };
    await logoService.updateActive(data).then();
  };
  const form = (
    <>
      <Grid container justifyContent="end" alignItems="start">
        <Grid item xs={"auto"} pt={"4px"} pr={3}>
          <Typography className="label-input" style={{ textAlign: "end" }}>
            สถานะ
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Switch handleSwitch={handleActive} value={status} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" pt={2}>
        <Grid container justifyContent={"center"} item xs={10} sm={10} md={8} lg={5} xl={4} pt={1}>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="400px" sx={{ border: "1px solid #d6d6d6" }}>
            <img src={image || defaultImg} alt="img" loading="lazy" width={image ? "100%" : ""} height={image ? "400px" : ""} />
          </Box>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ fontWeight: "bold" }}>
              อัพโหลดรูปภาพ
            </Typography>
            <FormHelperText>ชนิดไฟล์ jpg,png,jpeg ขนาด 500*500</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FileInput name="file" handleFile={handleFile} fileName={fileName} ref={ref} accept="image/png, image/jpg, image/jpeg" />
          </Grid>
          <Grid item xs={11} sm={11} md={3} lg={2} xl={1}>
            <Button
              sx={styles.btnRound}
              variant="contained"
              color="primary"
              onClick={() => {
                ref.current?.openFileUpload();
              }}
            >
              Browse
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ fontWeight: "bold" }}>
              ชื่อบริษัทลูกค้า
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FormInput fullWidth size="small" name="name" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pt={2}>
          <Link to={backToPath} style={{ textDecoration: "none" }}>
            <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
              ยกเลิก
            </Button>
          </Link>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
