import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setTitleWithBack } from "store/reducer/appReducer";
import { saveSuccess, editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import FileInput from "components/FileInput";
import Switch from "components/Switch";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useLocation, useParams } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import { bannerService } from "services/user/banner.service";
import { errorMsg } from "helpers/helper";

const Schema = object({
  file: string().min(1, "กรุณาเลือกไฟล์"),
});
type ISchema = TypeOf<typeof Schema>;
export default function BannerForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const backToPath = "/banner";
  const params = useParams();
  const isCreate = location.pathname.search("create") !== -1;
  const [status, setStatus] = useState(true);
  const [fileName, setFileName] = useState<any>("");
  const [file, setFile] = useState<any>();
  const [video, setVideo] = useState<any>();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = { file: "" };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const ref = useRef<any>();
  useEffect(() => {
    if (isCreate) {
      dispatch(setTitleWithBack({ title: "เพิ่มแบนเนอร์", path: backToPath }));
    } else {
      dispatch(setTitleWithBack({ title: "แก้ไขแบนเนอร์", path: backToPath }));
      getById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getById = async () => {
    await bannerService.getById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      methods.setValue("file", data.name);
      setStatus(data.isActive);
      setFileName(data.name);
      setVideo(data.video);
    });
  };
  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    const data = {
      id: parseInt(params.id || ""),
      name: values.file,
      file: file,
    };
    setLoading(true);
    if (isCreate) {
      await bannerService
        .create(data)
        .then((item: any) => {
          const id = item.data?.data?.id;
          active(status, id);
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      if (!file) return setModal({ ...editSuccess, navigateTo: backToPath });
      await bannerService
        .update(data)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file extension out of scope [.mp4]") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์นามสกุล .mp4",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    }
    setLoading(false);
  };
  const handleFile = (item: any) => {
    methods.clearErrors();
    if (item) {
      methods.setValue("file", item.name);
      setFileName(item.name);
      setVideo(URL.createObjectURL(item));
      setFile(item);
    } else {
      methods.setValue("file", "");
      setFileName("");
      setVideo(null);
      setFile(null);
    }
  };
  const handleActive = (isActive: boolean) => {
    setStatus(isActive);
    if (!isCreate) active(isActive, params.id);
  };
  const active = async (status: boolean, id: any) => {
    const data = {
      id: id,
      isActive: status,
    };
    await bannerService.updateActive(data).then();
  };
  const form = (
    <>
      <Grid container justifyContent="end" alignItems="start">
        <Grid item xs={"auto"} pt={"4px"} pr={3}>
          <Typography className="label-input" style={{ textAlign: "end" }}>
            สถานะ
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Switch handleSwitch={handleActive} value={status} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" pt={2}>
        <Grid item xs={12} sm={12} md={10} lg={9} xl={7} pt={1}>
          <video controls width="100%" src={video}>
            Sorry, your browser doesn't support videos.
          </video>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={15}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">อัพโหลดไฟล์วิดีโอ</Typography>
            <FormHelperText>ชนิดไฟล์ .mp4</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FileInput name="file" handleFile={handleFile} fileName={fileName} ref={ref} accept="video/mp4" />
          </Grid>
          <Grid item xs={11} sm={11} md={3} lg={2} xl={1}>
            <Button
              sx={styles.btnRound}
              variant="contained"
              color="primary"
              onClick={() => {
                ref.current?.openFileUpload();
              }}
            >
              Browse
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" pl={5} pt={5}>
          <Link to={backToPath} style={{ textDecoration: "none" }}>
            <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
              ยกเลิก
            </Button>
          </Link>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
