import { styled, TableCell, tableCellClasses } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  borderBottom: "none",
}));

export default function CustomTableCell(props: any) {
  return <StyledTableCell {...props}>{props.children}</StyledTableCell>;
}
