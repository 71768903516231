import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setProfile, setTitle } from "store/reducer/appReducer";
import FormInput from "components/FormInput";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { authService } from "services/authentication/auth.service";
import { selectRootAdmin } from "store/reducer/appReducer";
import type { Profile } from "interfaces/authentication/auth.interface";
import { IModal } from "interfaces/utils/utils.interface";
import { styles } from "assets/styles";
import { saveFail, saveSuccess, somethingWrong } from "helpers/utility";
const profileSchema = object({
  userId: string(),
  firstname: string()
    .min(1, "กรุณาระบุชื่อ")
    .regex(/^[A-Za-zก-๏]+$/, "สามารถระบุชื่อได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น"),
  lastname: string()
    .min(1, "กรุณาระบุนามสกุล")
    .regex(/^[A-Za-zก-๏]+$/, "สามารถระบุนามสกุลได้เฉพาะอักษรภาษาไทยและภาษาอังกฤษ เท่านั้น"),
  email: string().min(1, "กรุณาระบุอีเมล").email("รูปแบบอีเมลไม่ถูกต้อง"),
  tel: string()
    .regex(/^[0-9]*$/, "กรุณาระบุหมายเลขโทรศัพท์เป็นตัวเลขเท่านั้น")
    .min(10, "กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว")
    .max(10, "กรุณาระบุเบอร์โทรศัพท์ไม่เกิน 10 ตัว"),
});
type IProfile = TypeOf<typeof profileSchema>;

export default function AdminProfile() {
  const isRootAdmin = useSelector(selectRootAdmin);
  const dispatch = useDispatch();
  const [modal, setModal] = useState<IModal>({
    open: false,
    status: "",
    message: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<Profile>({
    firstname: "",
    lastname: "",
    email: "",
    tel: "",
    isActive: false,
  });
  const defaultValues: IProfile = {
    userId: "0002",
    firstname: "Mary",
    lastname: "Wagner",
    email: "mary_wagner@gmail.com",
    tel: "0965870379",
  };
  const methods = useForm<IProfile>({
    resolver: zodResolver(profileSchema),
    defaultValues,
  });
  useEffect(() => {
    dispatch(setTitle("จัดการโปรไฟล์ส่วนตัว"));

    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRootAdmin, dispatch, methods]);
  const getProfile = async () => {
    if (isRootAdmin) {
      await authService.getRootProfile().then((item: any) => {
        const data = item.data.data ?? item.data;
        setValues({
          ...values,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          tel: data.tel,
        });
        setMethodValues(data);
      });
    } else {
      await authService.getUserProfile().then((item: any) => {
        const data = item.data.data ?? item.data;
        setValues({
          ...values,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          tel: data.tel,
        });
        setMethodValues(data);
      });
    }
  };
  const setMethodValues = (value: any) => {
    methods.setValue("userId", `ID${String(value.id).padStart(4, "0")}`);
    methods.setValue("firstname", value.firstname);
    methods.setValue("lastname", value.lastname);
    methods.setValue("email", value.email);
    methods.setValue("tel", value.tel);
  };
  const onSubmitHandler: SubmitHandler<IProfile> = async (values: IProfile) => {
    setLoading(true);
    if (isRootAdmin) {
      await authService
        .updateRootProfile(values.firstname, values.lastname, values.email, values.tel)
        .then(async (item) => {
          const data = item.data.data ?? item.data;
          dispatch(setProfile(data));
          setModal(saveSuccess);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 400) {
            setModal(saveFail);
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      await authService
        .updateAdminProfile(values.firstname, values.lastname, values.email, values.tel)
        .then(async (item) => {
          const data = item.data.data ?? item.data;
          dispatch(setProfile(data));
          setModal(saveSuccess);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 400) {
            setModal(saveFail);
          } else {
            setModal(somethingWrong);
          }
        });
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
        }}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Grid container justifyContent="center" alignItems="start" pl={3} pt={5}>
            <Grid item xs={12} md={3} container alignItems="center">
              <Typography mt={1} className="label-input">
                ID
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormInput
                className="input-disabled"
                fullWidth
                size="small"
                name="userId"
                disabled
                sx={{
                  mb: "1.5rem",
                  "& fieldset": { border: "none" },
                  borderRadius: "5px",
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="start" pl={3}>
            <Grid item xs={12} md={3} container alignItems="center">
              <Typography mt={1} className="label-input">
                ชื่อ
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormInput sx={{ mb: "1.5rem" }} fullWidth size="small" name="firstname" />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="start" pl={3}>
            <Grid item xs={12} md={3} container alignItems="center">
              <Typography mt={1} className="label-input">
                นามสกุล
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormInput sx={{ mb: "1.5rem" }} fullWidth size="small" name="lastname" />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="start" pl={3}>
            <Grid item xs={12} md={3} container alignItems="center">
              <Typography mt={1} className="label-input">
                เบอร์โทรศัพท์
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormInput sx={{ mb: "1.5rem" }} fullWidth size="small" name="tel" />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems="start" pl={3}>
            <Grid item xs={12} md={3} container alignItems="center">
              <Typography mt={1} className="label-input">
                อีเมล (สำหรับ Login)
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormInput sx={{ mb: "1.5rem" }} fullWidth size="small" name="email" />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="start">
            <Grid container justifyContent="center" my={2} item xs={2}>
              <Button sx={styles.btnConfirm} variant="contained" color="primary" type="submit">
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
