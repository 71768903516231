import { styled, TableRow } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    borderRadius: 15,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  ":hover": {
    backgroundColor: "#ddd",
  },
}));
export default function CustomTableRow(props: any) {
  return <StyledTableRow {...props}>{props.children}</StyledTableRow>;
}
