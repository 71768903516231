import Background from "assets/image/ic_bg.png";
export const styles = {
  btnAction: {
    width: "50px",
    height: "50px",
    minWidth: 0,
    minHeight: 0,
    ":hover": {
      boxShadow: 8,
    },
  },
  btnRoundExport: {
    borderRadius: 25,
    height: 45,
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    backgroundColor: "#ffa248",
    ":hover": {
      backgroundColor: "#fc8b00",
      boxShadow: 8,
    },
  },
  btnRound: {
    borderRadius: 25,
    height: 45,
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    ":hover": {
      boxShadow: 8,
    },
  },
  btnRoundOutline: {
    borderRadius: 25,
    height: 45,
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "none",
    boxShadow: "2",
    ":hover": {
      boxShadow: 8,
    },
  },
  btnConfirm: {
    borderRadius: 25,
    width: "100%",
    fontSize: "18px",
    fontWeight: "bold",
    background: "transparent linear-gradient(255deg, #0db1ee 0%, #0b66b3 58%, #0a52a4 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    opacity: 1,
  },
  btnFormConfirm: {
    borderRadius: 25,
    width: 200,
    fontSize: "22px",
    backgroundImage: "linear-gradient(to right, #0a52a4 0%, #0db1ee 51%, #0a52a4 100%)",
    textAlign: "center",
    transition: " 0.5s",
    backgroundSize: "200% auto",
    color: "white",
    boxShadow: 3,
    display: "block",
    mr: 2,
    mt: 3,
    ":hover": {
      boxShadow: 8,
      // backgroundPosition: "right center",
      color: "#fff",
      textDecoration: "none",
    },
  },
  btnFormCancle: {
    borderRadius: 25,
    width: 200,
    fontSize: "22px",
    mr: 2,
    mt: 3,
    boxShadow: 3,
    ":hover": {
      boxShadow: 8,
    },
  },
  btnBack: {
    backgroundColor: "white",
    width: "32px",
    height: "32px",
    minWidth: 0,
    minHeight: 0,
    mr: 2,
    borderRadius: "10px",
    boxShadow: 3,
    ":hover": {
      backgroundColor: "#dddddd",
    },
  },
  paperContainer: {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
  },
  txtDescription: {
    fontWeight: "bold",
  },
  txtLeftPadding: {
    fontSize: "25px",
  },
  forgotMargin: {
    marginTop: "10px",
    marginBottom: "25px",
  },
  radioBtn: {
    "& .MuiSvgIcon-root": {
      fontSize: 36,
    },
  },
};
