import CustomTableCell from "../components/CustomTableCell";
import { Header } from "interfaces/utils/utils.interface";
export function setHeader(headers: Header[]) {
  return headers.map((item: any, index: number) => {
    return (
      <CustomTableCell width={item.width} key={index} align={item.align || "left"} sx={{ pl: item.pl }}>
        {item.name}
      </CustomTableCell>
    );
  });
}
export const defaultModal = { open: false, status: "", message: "" };
export const defaultModalDelete = { ...defaultModal, open: true, message: "ยืนยันการลบข้อมูล", status: "warning" };

export const saveSuccess = { open: true, message: "บันทึกข้อมูลสำเร็จ", status: "success" };
export const editSuccess = { open: true, message: "แก้ไขข้อมูลสำเร็จ", status: "success" };

export const saveFail = { open: true, message: "บันทึกข้อมูลไม่สำเร็จ", status: "error" };
export const deleteFail = { open: true, message: "ลบข้อมูลไม่สำเร็จ", status: "error" };

export const deleteSuccess = { open: true, message: "ลบข้อมูลสำเร็จ", status: "success_delete" };

export const somethingWrong = { open: true, message: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง", status: "error" };
