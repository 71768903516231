import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";

class Service {
  public create(log: string): Promise<AxiosResponse<Response>> {
    return httpService.post<Response>(`admin/admin-log`, {
      menuFunc: log,
    });
  }
}

export const logService = new Service();
