import { Button, FormControl, Grid, InputAdornment, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setTitle } from "store/reducer/appReducer";
import FormInput from "components/FormInput";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { authService } from "services/authentication/auth.service";
import { selectRootAdmin } from "store/reducer/appReducer";
import { IModal } from "interfaces/utils/utils.interface";
import { styles } from "assets/styles";
import { IconButton } from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { defaultModal, saveFail, saveSuccess, somethingWrong } from "helpers/utility";

const changePasswordSchema = object({
  oldpassword: string()
    .min(1, "กรุณาระบุรหัสผ่าน")
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .regex(/^[A-Za-z0-9]*$/, "กรุณาระบุรหัสผ่านเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"),
  password: string()
    .min(1, "กรุณาระบุรหัสผ่าน")
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .regex(/^[A-Za-z0-9]*$/, "กรุณาระบุรหัสผ่านเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"),
  confirmPassword: string()
    .min(1, "กรุณาระบุรหัสผ่าน")
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .regex(/^[A-Za-z0-9]*$/, "กรุณาระบุรหัสผ่านเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"),
}).refine((data) => data.password === data.confirmPassword, {
  message: "รหัสผ่านใหม่และยืนยันรหัสไม่ตรงกัน",
  path: ["confirmPassword"],
});
type IChangePassword = TypeOf<typeof changePasswordSchema>;

interface State {
  oldpassword: string;
  showOldPassword: boolean;
  password: string;
  showPassword: boolean;
  confirmPassword: string;
  showConfirmPassword: boolean;
}
export default function AdminChangePassword() {
  const isRootAdmin = useSelector(selectRootAdmin);
  const dispatch = useDispatch();
  const [values, setValues] = useState<State>({
    oldpassword: "",
    showOldPassword: false,

    password: "",
    showPassword: false,

    confirmPassword: "",
    showConfirmPassword: false,
  });
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues: IChangePassword = {
    oldpassword: "",
    password: "",
    confirmPassword: "",
  };
  const methods = useForm<IChangePassword>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues,
  });
  useEffect(() => {
    dispatch(setTitle("เปลี่ยนรหัสผ่าน"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickShowPasswordOld = () => {
    setValues({
      ...values,
      showOldPassword: !values.showOldPassword,
    });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPasswordCF = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const onSubmitHandler: SubmitHandler<IChangePassword> = async (values: IChangePassword) => {
    setLoading(true);
    if (isRootAdmin) {
      await authService
        .updateRootPassword(values.oldpassword, values.password)
        .then(async (item: any) => {
          setModal(saveSuccess);
          setLoading(false);
          methods.reset();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "password is incorrect") {
              setModal({
                open: true,
                message: "รหัสผ่านไม่ถูกต้อง",
                status: "error",
              });
            } else {
              setModal(saveFail);
            }
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      await authService
        .updateAdminPassword(values.oldpassword, values.password)
        .then(async () => {
          setModal(saveSuccess);
          setLoading(false);
          methods.reset();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: "รหัสผ่านไม่ถูกต้อง",
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
        }}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Grid container pl={5} alignItems="start" pt={5}>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={2} container alignItems="center" mr={4}>
              <Typography mt={1} className="label-input">
                <span className="req">*</span>
                รหัสผ่านเก่า
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <FormControl fullWidth variant="outlined">
                <FormInput
                  sx={{ mb: "1.5rem" }}
                  fullWidth
                  size="small"
                  name="oldpassword"
                  placeholder="ระบุรหัสผ่านเก่า"
                  type={values.showOldPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordOld}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container pl={5} alignItems="start">
            <Grid item xs={12} sm={12} md={12} lg={3} xl={2} container alignItems="center" mr={4}>
              <Typography mt={1} className="label-input">
                <span className="req">*</span>
                รหัสผ่านใหม่
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <FormControl fullWidth variant="outlined">
                <FormInput
                  sx={{ mb: "1.5rem" }}
                  fullWidth
                  size="small"
                  name="password"
                  placeholder="ระบุรหัสผ่านใหม่"
                  type={values.showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container pl={5} alignItems="start">
            <Grid item xs={12} sm={12} md={12} lg={3} xl={2} container alignItems="center" mr={4}>
              <Typography mt={1} className="label-input">
                <span className="req">*</span>
                ยืนยันรหัสผ่านใหม่
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <FormControl fullWidth variant="outlined">
                <FormInput
                  sx={{ mb: "1.5rem" }}
                  fullWidth
                  size="small"
                  name="confirmPassword"
                  placeholder="ระบุยืนยันรหัสผ่านใหม่"
                  type={values.showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordCF}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="start">
            <Grid container my={2} item xs={2}>
              <Button sx={styles.btnConfirm} variant="contained" color="primary" type="submit">
                บันทึก
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
