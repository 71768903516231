import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/customer-logo/all?page=${page}&pageSize=${pageSize}`);
  }

  public getById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/customer-logo/${id}`);
  }
  public create(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_customer_logo");
    const formData = new FormData();
    formData.append("logoFile", data.logoFile);
    formData.append("name", data.name);
    formData.append("isActive", data.isActive);
    return httpService.post<Response>(`admin/customer-logo`, formData);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_customer_logo");
    const formData = new FormData();
    formData.append("logoFile", data.logoFile);
    formData.append("name", data.name);
    formData.append("isActive", data.isActive);
    return httpService.patch<Response>(`admin/customer-logo/${data.id}`, formData);
  }
  public updateActive(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_customer_logo");
    return httpService.patch<Response>(`admin/customer-logo/active/${data.id}`, {
      isActive: data.isActive,
    });
  }
  public delete(id: string): Promise<AxiosResponse<Response>> {
    logService.create("manage_customer_logo");
    return httpService.delete<Response>(`admin/customer-logo/${id}`);
  }
}

export const logoService = new Services();
