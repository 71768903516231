import type { Response } from "interfaces/utils/utils.interface";

import type { AxiosResponse } from "axios";
import httpService from "plugins/axios";

class AuthService {
  public login(email: string, password: string): Promise<AxiosResponse<Response>> {
    return httpService.post<Response>("/admin/login", {
      email,
      password,
    });
  }
  public logout(isRootAdmin: boolean): Promise<AxiosResponse> {
    localStorage.removeItem("cmt_token");
    localStorage.removeItem("cmt_admin");
    localStorage.removeItem("cmt_fullname");

    if (isRootAdmin) return httpService.post("/root/logout");
    else return httpService.post("/admin/logout");
  }
  public forgotPassword(email: string): Promise<AxiosResponse> {
    const callback = process.env.REACT_APP_CALLBACK_FORGOT_PASSOWRD;
    return httpService.post("/root/forgot-password", { email, callback });
  }
  public changePassword(password: string, token: string): Promise<AxiosResponse> {
    return httpService.post("/root/forgot-password/token", { password, token });
  }

  public getRootProfile(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>("/root/profile");
  }
  public getUserProfile(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>("/admin/profile");
  }

  public updateRootProfile(firstname: string, lastname: string, email: string, tel: string): Promise<AxiosResponse> {
    return httpService.put("/root/profile", {
      firstname,
      lastname,
      email,
      tel,
    });
  }
  public updateAdminProfile(firstname: string, lastname: string, email: string, tel: string): Promise<AxiosResponse> {
    return httpService.put("/admin/profile", {
      firstname,
      lastname,
      email,
      tel,
    });
  }
  public updateRootPassword(password: string, newpassword: string): Promise<AxiosResponse> {
    return httpService.put("/root/profile/password", { password, newpassword });
  }
  public updateAdminPassword(password: string, newpassword: string): Promise<AxiosResponse> {
    return httpService.put("/admin/profile/password", {
      password,
      newpassword,
    });
  }
}

export const authService = new AuthService();
