import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
export default function PageSize(props: any) {
  return (
    <Grid container justifyContent="end" alignContent="center">
      <Typography mt={1} mr={1}>
        <b>แถวต่อหน้า :</b>
      </Typography>
      <FormControl
        sx={{
          width: "80px",
        }}
      >
        <Select
          size="small"
          value={props.pageSize || `10`}
          onChange={props.handleChange}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
      <Typography mt={1} mx={1}>
        <b>{`${(props.page - 1) * props.pageSize + 1}-${
          props.pageSize * props.page > props.count
            ? props.count
            : props.page * props.pageSize
        } of ${props.count}`}</b>
      </Typography>
    </Grid>
  );
}
