import { Button, Grid, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setTitle } from "store/reducer/appReducer";
import { SelectChangeEvent } from "@mui/material/Select";
import PageSize from "components/PageSize";
import Pagination from "components/Pagination";
import { adminService } from "services/admin/admin.service";

import Loading from "components/Loading";
import Datepicker from "components/Datepicker";
import moment from "moment";
import { Search } from "@mui/icons-material";

import CustomTableCell from "components/CustomTableCell";
import CustomTableRow from "components/CustomTableRow";
import { styles } from "assets/styles";
import { setHeader } from "helpers/utility";
const headers = setHeader([
  { name: "วันที่/เวลา", align: "center" },
  { name: "ชื่อผู้ดูแลระบบ", align: "center" },
  { name: "เมนู", align: "center" },
]);

export default function LogUser() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState("5");
  const [pageCount, setPageCount] = useState("0");

  const [keyword, setKeyword] = useState("");

  const today = moment().format("YYYY-MM-DD");
  const lastWeek = moment().add(-6, "days").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(lastWeek);
  const [endDate, setEndDate] = useState(today);
  const [validStartDate, setValidStartDate] = useState("");
  const [validEndDate, setValidEndDate] = useState("");

  const [dataTable, setDataTable] = useState<any>([]);

  const searchLogUser = async () => {
    if (!startDate || !endDate) {
      if (!startDate) setValidStartDate("valid");
      if (!endDate) setValidEndDate("valid");
      return;
    }

    const log = {
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      page: page,
      pageSize: pageSize,
    };
    await adminService.searchLogUser(log).then((item: any) => {
      const data = item.data.data ?? item.data;
      if (item.data.count !== 0) {
        setPageCount(item.data.count || 1);
      }
      setDataTable(data);
    });
  };
  const resetPage = () => {
    setPage(1);
    setPageCount("0");
  };
  const handleKeyword = (value: any) => {
    if (keyword !== value) resetPage();
    setKeyword(value);
  };
  const handleStartDate = (value: any) => {
    if (startDate !== value) resetPage();
    setStartDate(value);
  };
  const handleEndDate = (value: any) => {
    if (endDate !== value) resetPage();
    setEndDate(value);
  };
  useEffect(() => {
    dispatch(setTitle("Log การใช้งานของผู้ดูแลระบบ"));
    searchLogUser();

    if (startDate) setValidStartDate("");
    if (endDate) setValidEndDate("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, keyword, startDate, endDate]);

  const handleChange = (event: SelectChangeEvent) => setPageSize(event.target.value as string);
  const handleChangePage = (value: any) => setPage(value);

  const exportExcel = async () => {
    if (!startDate || !endDate) {
      if (!startDate) setValidStartDate("valid");
      if (!endDate) setValidEndDate("valid");
      return;
    }
    setLoading(true);
    const log = {
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      page: page,
      pageSize: pageSize,
      download: true,
    };
    await adminService.searchLogUser(log).then((response: any) => {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `export_user_log_${startDate}_to_${endDate}`);
      document.body.appendChild(link);
      link.click();
    });
    setLoading(false);
  };
  return (
    <>
      <Loading loading={loading} />
      <Grid container alignContent="center" mb={3}>
        <Grid container justifyContent="start" item xs={12} md={8} spacing={2}>
          <Grid item sm={12} md={"auto"}>
            <Typography mt="6px" pr="20px" className="label-input">
              ค้นหา
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={keyword}
              onChange={(e) => handleKeyword(e.target.value)}
              className="placeholder-overflow"
              sx={{
                ml: 1,
                flex: 1,
                width: "95%",
                "& fieldset": {
                  borderRadius: `25px`,
                  border: "none",
                  boxShadow: 3,
                  height: "55px",
                },
              }}
              placeholder="ค้นหาชื่อผู้ดูแลระบบ,เมนู"
              InputProps={{
                endAdornment: <Search color="disabled" />,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid container justifyContent="start" item xs={12} sm={8} md={9} lg={9} xl={10} spacing={2}>
          <Grid item sm={12} md={"auto"}>
            <Typography mt="3px" className="label-input">
              ช่วงวันที่
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} mb={2}>
            <Datepicker
              props={{
                valid: validStartDate,
                onChange: handleStartDate,
                value: startDate,
                placeholder: "วันที่เริ่มต้น",
              }}
            ></Datepicker>
          </Grid>
          <Grid container item xs={"auto"}>
            <b
              style={{
                marginTop: "6px",
              }}
            >
              -
            </b>
          </Grid>
          <Grid item xs={12} md={4} mb={2}>
            <Datepicker
              props={{
                valid: validEndDate,
                onChange: handleEndDate,
                value: endDate,
                placeholder: "วันที่สิ้นสุด",
              }}
            ></Datepicker>
          </Grid>

          <Grid item xs={12} md={2} mb="15px">
            <Button style={{ backgroundColor: "white" }} sx={styles.btnRoundOutline} variant="outlined" color="primary" onClick={() => searchLogUser()}>
              ค้นหา
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent="end" item xs={6} md={3} lg={3} xl={2} pr={4} mb="15px">
          <Button
            onClick={() => {
              exportExcel();
            }}
            sx={styles.btnRoundExport}
            variant="contained"
            type="submit"
          >
            ส่งออก Excel
          </Button>
        </Grid>
      </Grid>
      <TableContainer sx={{ pt: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead className="table-header">
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>
            {dataTable.length > 0 ? (
              dataTable.map((row: any) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell align="center">{moment(row.createdAt).format("DD/MM/YYYY HH:mm น.") || ""}</CustomTableCell>
                  <CustomTableCell align="center">{row.name}</CustomTableCell>
                  <CustomTableCell align="center">{row.menuFuncTh}</CustomTableCell>
                </CustomTableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination count={parseInt(`${Math.ceil(parseInt(pageCount) / parseInt(pageSize))}`)} page={page} handleChange={handleChangePage} />
      <PageSize pageSize={pageSize} page={page} count={pageCount} handleChange={handleChange}></PageSize>
    </>
  );
}
