import { Button, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import IconBin from "assets/icon/ic_bin.png";
import IconEdit from "assets/icon/ic_edit.png";
import IconView from "assets/icon/ic_view.png";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import ModalConfirm from "components/DialogConfirm";
import Modal from "components/Dialog";
import { IModal } from "interfaces/utils/utils.interface";
import PageSize from "components/PageSize";
import Pagination from "components/Pagination";
import CustomTableCell from "components/CustomTableCell";
import CustomTableRow from "components/CustomTableRow";
import { styles } from "assets/styles";
import { setHeader, defaultModal, defaultModalDelete, deleteSuccess, deleteFail } from "helpers/utility";
import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { setTitle } from "store/reducer/appReducer";
import { goodsService } from "services/user/goodsService.service";
const headers = setHeader([
  { name: "รูป", pl: 10, align: "center", width: "15%" },
  { name: "ชื่อสินค้า", align: "center" },
  { name: "สถานะ", align: "center", width: "20%" },
  { name: "จัดการ", align: "center", width: "20%" },
]);
export default function GoodsServices() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [pageSize, setPageSize] = useState("5");
  const [pageCount, setPageCount] = useState("0");
  const [dataTable, setDataTable] = useState<any>([]);
  const [modalConfirm, setModalConfirm] = useState<IModal>(defaultModal);
  const [modal, setModal] = useState<IModal>(defaultModal);
  const handleChangePageSize = (event: SelectChangeEvent) => setPageSize(event.target.value as string);
  const handleChangePage = (value: any) => setPage(value);
  const configModal = (deleteId: number) => {
    setDeleteId(deleteId);
    setModalConfirm(defaultModalDelete);
  };
  const handleModalConfirm = (confirm: any) => {
    if (confirm === true) deleteEntity(deleteId);
    setDeleteId(null);
    setModalConfirm({ ...modalConfirm, open: false });
  };
  const handleModal = (confirm: any) => {
    setModal({ ...modal, open: confirm });
  };
  const deleteEntity = async (id: number) => {
    await goodsService
      .delete(String(id))
      .then(() => {
        setModal(deleteSuccess);
        get();
      })
      .catch(() => {
        setModal(deleteFail);
      });
  };
  useEffect(() => {
    dispatch(setTitle("จัดการสินค้าและบริการ"));
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);
  const get = async () => {
    await goodsService.get(String(page), pageSize).then((item: any) => {
      const data = item.data.data ?? item.data;
      if (item.data.count !== 0) {
        setPageCount(item.data.count || 1);
      }
      setDataTable(data);
    });
  };
  return (
    <>
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: handleModal,
        }}
      />
      <ModalConfirm
        props={{
          modalOpen: modalConfirm.open,
          modalStatus: modalConfirm.status,
          modalMessage: modalConfirm.message,
          handleModal: handleModalConfirm,
        }}
      />
      <Grid container alignContent="center" justifyContent="end" mb={3}>
        <Grid item xs={4} sm={10} md={4} lg={3} xl={2}>
          <Link to="create" style={{ textDecoration: "none" }}>
            <Button sx={styles.btnRound} variant="contained" color="primary" type="submit" startIcon={<AddIcon></AddIcon>}>
              เพิ่มสินค้าและบริการ
            </Button>
          </Link>
        </Grid>
      </Grid>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead className="table-header">
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>
            {dataTable.length > 0 ? (
              dataTable.map((row: any) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell align="center" sx={{ pl: 10 }}>
                    <img src={row.productImg} alt="img" width={60} height={50} />
                  </CustomTableCell>
                  <CustomTableCell align="left" style={{ transform: "translateX(25%)" }}>{`${row.nameTh}`}</CustomTableCell>
                  <CustomTableCell align="center" className={row.isActive ? "text-active" : "text-inactive"}>
                    {row.isActive ? "Active" : "InActive"}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <Link to={`detail/${row.id}`}>
                      <Button variant="text" style={styles.btnAction}>
                        <img src={IconView} alt="action" width="100%" />
                      </Button>
                    </Link>
                    <Link to={`edit/${row.id}`}>
                      <Button variant="text" style={styles.btnAction}>
                        <img src={IconEdit} alt="action" width="100%" />
                      </Button>
                    </Link>
                    <Button variant="text" style={styles.btnAction} onClick={() => configModal(row.id)}>
                      <img src={IconBin} alt="action" width="100%" />
                    </Button>
                  </CustomTableCell>
                </CustomTableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination count={parseInt(`${Math.ceil(parseInt(pageCount) / parseInt(pageSize))}`)} page={page} handleChange={handleChangePage} />
      <PageSize pageSize={pageSize} page={page} count={pageCount} handleChange={handleChangePageSize}></PageSize>
    </>
  );
}
