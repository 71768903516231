import React, { useState } from "react";

import { Paper, FormControl, InputAdornment, IconButton, Grid, Button } from "@mui/material";

import Visibility from "assets/icon/ic_eye.svg";
import VisibilityOff from "assets/icon/ic_eye_off.png";

import LockIcon from "assets/icon/ic_key.png";

import Logo from "assets/logo/ic_bg_small@2x.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Modal from "components/Dialog";
import Loading from "components/Loading";

import { authService } from "services/authentication/auth.service";

import FormInput from "components/FormInput";

import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Link, useSearchParams } from "react-router-dom";
import { IModal } from "interfaces/utils/utils.interface";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";

interface State {
  password: string;
  showPassword: boolean;
  confirmPassword: string;
  showConfirmPassword: boolean;
}

const changePasswordSchema = object({
  password: string()
    .min(1, "กรุณาระบุรหัสผ่าน")
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .regex(/^[A-Za-z0-9]*$/, "กรุณาระบุรหัสผ่านเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"),
  confirmPassword: string()
    .min(1, "กรุณาระบุรหัสผ่าน")
    .min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร")
    .regex(/^[A-Za-z0-9]*$/, "กรุณาระบุรหัสผ่านเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"),
}).refine((data) => data.password === data.confirmPassword, {
  message: "รหัสผ่านไม่ตรงกัน",
  path: ["confirmPassword"],
});
type IChangePassword = TypeOf<typeof changePasswordSchema>;
export default function ChangePassword() {
  const [searchParams] = useSearchParams();
  const [token] = React.useState<any>(searchParams.get("token"));

  const [values, setValues] = useState<State>({
    password: "",
    showPassword: false,

    confirmPassword: "",
    showConfirmPassword: false,
  });
  const [modal, setModal] = useState<IModal>(defaultModal);

  const [loading, setLoading] = useState<boolean>(false);

  const defaultValues: IChangePassword = {
    password: values.password,
    confirmPassword: values.confirmPassword,
  };
  const methods = useForm<IChangePassword>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPasswordCF = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmitHandler: SubmitHandler<IChangePassword> = async (values: IChangePassword) => {
    setLoading(true);

    await authService
      .changePassword(values.password, token)
      .then(async () => {
        setLoading(false);
        setModal({
          open: true,
          message: "เปลี่ยนรหัสผ่านสำเร็จ",
          status: "success",
          navigateTo: "/login",
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 400) {
          setModal({
            open: true,
            message: "เปลี่ยนรหัสผ่านไม่สำเร็จ",
            status: "error",
          });
        } else {
          setModal({
            open: true,
            message: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
            status: "error",
          });
        }
      });
  };
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          style={styles.paperContainer}
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={5}
            sm={12}
            md={5}
            lg={6}
            sx={{
              height: "100vh",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100vh",
              }}
            >
              <img style={{ paddingLeft: "2px", paddingBottom: "5px" }} src={Logo} alt="logo" />
              <div style={styles.txtLeftPadding}>Central Metals (Thailand) Ltd.</div>
            </Grid>
          </Grid>
          <Grid container alignContent={"center"} justifyContent={"center"} item xs={12} sm={12} md={7} lg={6} sx={{ pr: 7, pl: 1, height: "100vh" }}>
            <Grid item xs={10} md={10}>
              <Paper elevation={4} square sx={{ height: "70vh", minHeight: "500px" }}>
                <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      py: 5,
                    }}
                    justifyContent="center"
                  >
                    <Grid item xs={8}>
                      <Grid container item sx={{ pb: 10, pt: 5 }}>
                        <div
                          style={{
                            fontSize: "37px",
                          }}
                        >
                          <Link to="/">
                            <Button size="small" variant="contained" className="nav-back-btn" sx={styles.btnBack}>
                              <ArrowBackIcon color="primary" sx={{ fontSize: 22, fontWeight: "bold" }}></ArrowBackIcon>
                            </Button>
                          </Link>
                          <label>เปลี่ยนรหัสผ่าน</label>
                        </div>
                      </Grid>
                      <Grid item pb={3}>
                        <FormControl fullWidth variant="outlined">
                          <b>รหัสผ่านใหม่</b>
                          <FormInput
                            variant="standard"
                            name="password"
                            placeholder="รหัสผ่านใหม่"
                            type={values.showPassword ? "text" : "password"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img style={{ paddingLeft: "2px" }} src={LockIcon} alt="iconLock" width="70%" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showPassword ? (
                                      <img src={Visibility} alt="Visibility" width="80%" />
                                    ) : (
                                      <img src={VisibilityOff} alt="VisibilityOff" width="80%" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item pb={5}>
                        <FormControl fullWidth variant="outlined">
                          <b>ยืนยันรหัสผ่านใหม่</b>
                          <FormInput
                            variant="standard"
                            name="confirmPassword"
                            placeholder="ยืนยันรหัสผ่านใหม่"
                            type={values.showConfirmPassword ? "text" : "password"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img style={{ paddingLeft: "2px" }} src={LockIcon} alt="iconLock" width="70%" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordCF}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showConfirmPassword ? (
                                      <img src={Visibility} alt="Visibility" width="80%" />
                                    ) : (
                                      <img src={VisibilityOff} alt="VisibilityOff" width="80%" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item container justifyContent="center">
                        <Button style={styles.btnConfirm} className="btn-grad" variant="contained" color="primary" type="submit">
                          ตกลง
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
