import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/about-us`);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_about_us");
    const formData = new FormData();
    formData.append("aboutUsFile", data.file);
    formData.append("titleTh", data.titleTh);
    formData.append("titleEn", data.titleEn);
    formData.append("detailTh", data.detailTh);
    formData.append("detailEn", data.detailEn);
    return httpService.put<Response>(`admin/about-us`, formData);
  }
  public updateActive(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_about_us");
    return httpService.patch<Response>(`admin/about-us/active `, {
      isActive: data.isActive,
    });
  }
}

export const aboutService = new Services();
