import { FormControl, FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";

export default function IndeterminateCheckbox(props: any) {
  const [checked, setChecked] = useState(false);
  const [x, setX] = useState(false);

  const handleAll = () => {
    props.list?.map((item: any) => {
      if (checked) {
        item.value = false;
        props.handleValid(true);
      } else {
        item.value = true;
        props.handleValid(false);
      }
      return item;
    });
    props.onChange(props.list);
    if (checked) setChecked(false);
    else {
      setChecked(true);
    }
  };

  const handleChange = (value: any) => {
    let index = props.list?.findIndex((item: any) => item === value);
    if (props.list[index].value) props.list[index].value = false;
    else {
      props.list[index].value = true;
    }
    props.onChange(props.list);

    const flag = props.list.every((item: any) => item.value === false);
    props.handleValid(flag);
    //fake re render
    setX(!x);
  };
  useEffect(() => {
    const flag = props.list.every((item: any) => item.value === true);
    if (flag) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [props]);

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {props.list.map((item: any, index: number) => {
        return <FormControlLabel key={index} label={item.name} control={<Checkbox checked={item.value} onChange={() => handleChange(item)} />} />;
      })}
    </Box>
  );

  return (
    <div>
      <FormControl required error={props.validate} component="fieldset" sx={{ mt: 1 }} variant="standard">
        {props.validate ? (
          <>
            <FormHelperText>{`กรุณาเลือก${props.name}`}</FormHelperText>
          </>
        ) : (
          <></>
        )}

        <FormControlLabel
          label="ทั้งหมด"
          control={
            <Checkbox
              checked={checked}
              // indeterminate={checked[0] !== checked[1]}
              onChange={handleAll}
            />
          }
        />
        {children}
      </FormControl>
    </div>
  );
}
