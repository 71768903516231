import { Link, useLocation } from "react-router-dom";
import { setTitleWithBack } from "store/reducer/appReducer";
import { useDispatch } from "react-redux";
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, Paper, Radio, RadioGroup, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { saveSuccess, editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import FileInput from "components/FileInput";
import Switch from "components/Switch";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import defaultImg from "assets/image/ic_image.png";
import FormInput from "components/FormInput";
import { Box } from "@mui/system";
import { activityService } from "services/user/activity.service";
import { errorMsg } from "helpers/helper";
import ReactPlayer from "react-player";
const Schema = object({
  fileImage: string().min(1, "กรุณาเลือกไฟล์"),
  fileVideo: string().min(1, "กรุณาเลือกไฟล์"),
  nameTh: string().min(1, "กรุณาระบุหัวข้อภาษาไทย"),
  nameEn: string().min(1, "กรุณาระบุหัวข้อภาษาอังกฤษ"),
  detailTh: string().min(1, "กรุณาระบุรายละเอียดภาษาไทย"),
  detailEn: string().min(1, "กรุณาระบุรายละเอียดภาษาอังกฤษ"),
});
type ISchema = TypeOf<typeof Schema>;
export default function ActivityForm() {
  const backToPath = "/activity";
  const refVideo = useRef<any>();
  const refImage = useRef<any>();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [imageFileName, setImageFileName] = useState("");
  const [imgFileItem, setImageFileItem] = useState<any>();
  const [image, setImage] = useState<any>();
  const [videoFileName, setVideoFileName] = useState("");
  const [videoFileItem, setVideoFileItem] = useState<any>();
  const [video, setVideo] = useState<any>();

  const [selectMediaType, setSelectMediaType] = useState("");
  const [validate, setValidate] = useState(false);
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = { fileImage: "", fileVideo: "", nameTh: "", nameEn: "", detailTh: "", detailEn: "" };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const location = useLocation();
  const isCreate = location.pathname.search("create") !== -1;
  const isEdit = location.pathname.search("edit") !== -1;
  const isView = location.pathname.search("detail") !== -1;
  const params = useParams();
  const getById = async () => {
    await activityService.getById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      setStatus(data.isActive);

      setSelectMediaType(data.fileType);
      if (data.activityImg) {
        setImageFileName(data.activityImg);
        setImage(data.activityImg);
        methods.setValue("fileImage", data.activityImg);
      } else {
        resetImageFile();
      }

      if (data.activityVideo) {
        setVideoFileName(data.activityVideo);
        setVideo(data.activityVideo);
        methods.setValue("fileVideo", data.activityVideo);
      } else {
        resetVideoFile();
      }

      methods.setValue("nameTh", data.nameTh);
      methods.setValue("nameEn", data.nameEn);
      methods.setValue("detailTh", data.detailTh);
      methods.setValue("detailEn", data.detailEn);
    });
  };
  useEffect(() => {
    if (isView) {
      getById();
      dispatch(setTitleWithBack({ title: "ดูรายละเอียดกิจกรรม", path: backToPath }));
    } else if (isEdit) {
      getById();
      dispatch(setTitleWithBack({ title: "แก้ไขกิจกรรม", path: backToPath }));
    } else dispatch(setTitleWithBack({ title: "เพิ่มกิจกรรม", path: backToPath }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    if (validTypeAndFile(values)) return;
    const data = {
      id: parseInt(params.id || ""),
      imgFile: imgFileItem,
      videoFile: videoFileItem,
      nameTh: values.nameTh,
      nameEn: values.nameEn,
      detailTh: values.detailTh,
      detailEn: values.detailEn,
      isActive: status,
      fileType: selectMediaType,
    };
    setLoading(true);
    if (isCreate) {
      await activityService
        .create(data)
        .then(() => {
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file size is too large") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 10MB",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      await activityService
        .update(data)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file size is too large") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 10MB",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    }

    setLoading(false);
  };
  const validTypeAndFile = (values: any) => {
    if (selectMediaType === "image" && imgFileItem === undefined && values.fileImage === "undefined") {
      methods.setError("fileImage", { message: "กรุณาเลือกไฟล์" });
      return true;
    } else if (selectMediaType === "video" && videoFileItem === undefined && values.fileVideo === "undefined") {
      methods.setError("fileVideo", { message: "กรุณาเลือกไฟล์" });
      return true;
    }
    return false;
  };
  const handleImageFile = (item: any) => {
    methods.clearErrors("fileImage");
    if (item) {
      methods.setValue("fileImage", item.name);
      setImageFileName(item.name);
      setImageFileItem(item);
      setImage(URL.createObjectURL(item));
      resetVideoFile();
    } else {
      resetVideoFile();
      resetImageFile();
    }
  };
  const handleVideoFile = (item: any) => {
    methods.clearErrors("fileVideo");
    if (item) {
      methods.setValue("fileVideo", item.name);
      setVideoFileName(item.name);
      setVideoFileItem(item);
      setVideo(URL.createObjectURL(item));
      resetImageFile();
    } else {
      resetVideoFile();
      resetImageFile();
    }
  };
  const resetVideoFile = () => {
    methods.setValue("fileVideo", "undefined");
    setVideoFileName("");
    setVideoFileItem(undefined);
    setVideo(undefined);
  };
  const resetImageFile = () => {
    methods.setValue("fileImage", "undefined");
    setImageFileName("");
    setImageFileItem(undefined);
    setImage(undefined);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectMediaType(event.target.value);
    setValidate(false);
  };
  const handleValid = () => {
    if (!selectMediaType) setValidate(true);
    else {
      setValidate(false);
    }
  };
  const handleActive = (isActive: boolean) => {
    setStatus(isActive);
    if (!isCreate) active(isActive, params.id);
  };
  const active = async (status: boolean, id: any) => {
    const data = {
      id: id,
      isActive: status,
    };
    await activityService.updateActive(data).then();
  };
  const form = (
    <>
      <Grid container justifyContent="end" alignItems="start">
        <Grid item xs={"auto"} pt={"4px"} pr={3}>
          <Typography className="label-input" style={{ textAlign: "end" }}>
            สถานะ
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          {isView ? (
            <Typography className={status ? "text-active" : "text-inactive"} style={{ fontSize: "22px", paddingTop: "4px", paddingRight: "30px" }}>
              {status ? "Active" : "InActive"}
            </Typography>
          ) : (
            <Switch handleSwitch={handleActive} value={status} />
          )}
        </Grid>
      </Grid>
      <Paper elevation={4} sx={{ borderRadius: 5, mt: 2, pb: 5 }}>
        {isView ? (
          <></>
        ) : (
          <Grid container justifyContent="start" alignItems="center" pl={5} pt={8}>
            <FormControl>
              <RadioGroup row name="radio" value={selectMediaType} onChange={handleChange}>
                <FormControlLabel value="image" control={<Radio sx={styles.radioBtn} />} label="อัพโหลดรูปภาพ" className="radio-text" />
                <FormControlLabel value="video" control={<Radio sx={styles.radioBtn} />} label="อัพโหลดไฟล์วิดีโอ" className="radio-text" />
              </RadioGroup>
              {validate ? <FormHelperText style={{ color: "red" }}>{"กรุณาเลือกข้อมูลที่ต้องการอัพโหลด"}</FormHelperText> : <></>}
            </FormControl>
          </Grid>
        )}

        {selectMediaType === "video" ? (
          !isCreate ? (
            <Grid container justifyContent="center" alignItems="center" pt={2}>
              <Grid item xs={12} sm={12} md={10} lg={9} xl={7} pt={1}>
                <ReactPlayer controls width="100%" height="100%" url={video}></ReactPlayer>
              </Grid>
            </Grid>
          ) : (
            <></>
          )
        ) : selectMediaType === "image" ? (
          <Grid container justifyContent="center" alignItems="center" pt={2}>
            <Grid container justifyContent={"center"} item xs={10} sm={10} md={8} lg={5} xl={4} pt={1}>
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="400px" sx={{ border: "1px solid #d6d6d6" }}>
                <img src={image || defaultImg} alt="img" loading="lazy" width={image ? "100%" : ""} height={image ? "400px" : ""} />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          {selectMediaType === "video" ? (
            <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
                <Typography className="label-input" sx={{ fontWeight: "bold" }}>
                  อัพโหลดไฟล์วิดีโอ
                </Typography>
                <FormHelperText>ชนิดไฟล์ .mp4</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
                <FileInput name="fileVideo" handleFile={handleVideoFile} fileName={videoFileName} ref={refVideo} disabled={isView} accept="video/mp4 " />
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={2} xl={1}>
                {isView ? (
                  <></>
                ) : (
                  <Button
                    sx={styles.btnRound}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      refVideo.current?.openFileUpload();
                    }}
                  >
                    Browse
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : selectMediaType === "image" ? (
            <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
                <Typography className="label-input" sx={{ fontWeight: "bold" }}>
                  อัพโหลดรูปภาพ
                </Typography>
                <FormHelperText>ชนิดไฟล์ jpg,png,jpeg ขนาด 1000*500</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
                <FileInput
                  name="fileImage"
                  handleFile={handleImageFile}
                  fileName={imageFileName}
                  ref={refImage}
                  disabled={isView}
                  accept="image/png, image/jpg, image/jpeg"
                />
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={2} xl={1}>
                {isView ? (
                  <></>
                ) : (
                  <Button
                    sx={styles.btnRound}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      refImage.current?.openFileUpload();
                    }}
                  >
                    Browse
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
                ชื่อกิจกรรม
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาไทย</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
              <FormInput fullWidth size="small" name="nameTh" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาอังกฤษ</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
              <FormInput fullWidth size="small" name="nameEn" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
                รายละเอียด
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาไทย</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <FormInput fullWidth multiline rows={8} size="small" name="detailTh" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาอังกฤษ</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <FormInput fullWidth multiline rows={8} size="small" name="detailEn" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          {isView ? (
            <></>
          ) : (
            <Grid container justifyContent="center" pt={2}>
              <Link to={backToPath} style={{ textDecoration: "none" }}>
                <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
                  ยกเลิก
                </Button>
              </Link>
              <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit" onClick={() => handleValid()}>
                บันทึก
              </Button>
            </Grid>
          )}
        </form>
      </Paper>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
