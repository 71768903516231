import { Link, useLocation } from "react-router-dom";
import { setTitleWithBack } from "store/reducer/appReducer";
import { useDispatch } from "react-redux";
import { Button, FormHelperText, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { saveSuccess, editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import FileInput from "components/FileInput";
import Switch from "components/Switch";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import defaultImg from "assets/image/ic_image.png";
import FormInput from "components/FormInput";
import { Box } from "@mui/system";
import { goodsService } from "services/user/goodsService.service";
import { errorMsg } from "helpers/helper";
const Schema = object({
  file: string().min(1, "กรุณาเลือกไฟล์"),
  nameTh: string().min(1, "กรุณาระบุหัวข้อภาษาไทย"),
  nameEn: string().min(1, "กรุณาระบุหัวข้อภาษาอังกฤษ"),
  detailTh: string().min(1, "กรุณาระบุรายละเอียดภาษาไทย"),
  detailEn: string().min(1, "กรุณาระบุรายละเอียดภาษาอังกฤษ"),
});
type ISchema = TypeOf<typeof Schema>;
export default function GoodsServicesForm() {
  const backToPath = "/goods/services";
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<any>();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = { file: "", nameTh: "", nameEn: "", detailTh: "", detailEn: "" };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const location = useLocation();

  const isCreate = location.pathname.search("create") !== -1;
  const isEdit = location.pathname.search("edit") !== -1;
  const isView = location.pathname.search("detail") !== -1;

  const params = useParams();
  const getById = async () => {
    await goodsService.getById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      setStatus(data.isActive);

      setFileName(data.productImg);
      setImage(data.productImg);
      methods.setValue("file", data.productImg);
      methods.setValue("nameTh", data.nameTh);
      methods.setValue("nameEn", data.nameEn);
      methods.setValue("detailTh", data.detailTh);
      methods.setValue("detailEn", data.detailEn);
    });
  };
  useEffect(() => {
    if (isView) {
      getById();
      dispatch(setTitleWithBack({ title: "ดูรายละเอียดสินค้าและบริการ", path: backToPath }));
    } else if (isEdit) {
      getById();
      dispatch(setTitleWithBack({ title: "แก้ไขสินค้าและบริการ", path: backToPath }));
    } else dispatch(setTitleWithBack({ title: "เพิ่มสินค้าและบริการ", path: backToPath }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    const data = {
      id: parseInt(params.id || ""),
      productFile: file,
      nameTh: values.nameTh,
      nameEn: values.nameEn,
      detailTh: values.detailTh,
      detailEn: values.detailEn,
      isActive: status,
    };
    setLoading(true);
    if (isCreate) {
      await goodsService
        .create(data)
        .then(() => {
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file size is too large") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 10MB",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      await goodsService
        .update(data)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.errors === "error, file size is too large") {
              setModal({
                open: true,
                message: "กรุณาอัพโหลดไฟล์ขนาดไม่เกิน 10MB",
                status: "error",
              });
            } else {
              setModal({
                open: true,
                message: errorMsg(err),
                status: "error",
              });
            }
          } else {
            setModal(somethingWrong);
          }
        });
    }
    setLoading(false);
  };
  const handleFile = (item: any) => {
    methods.clearErrors();
    if (item) {
      methods.setValue("file", item.name);
      setFileName(item.name);
      setImage(URL.createObjectURL(item));
      setFile(item);
    } else {
      methods.setValue("file", "");
      setFileName("");
      setImage(undefined);
      setFile(undefined);
    }
  };
  const handleActive = (isActive: boolean) => {
    setStatus(isActive);
    if (!isCreate) active(isActive, params.id);
  };
  const active = async (status: boolean, id: any) => {
    const data = {
      id: id,
      isActive: status,
    };
    await goodsService.updateActive(data).then();
  };

  const form = (
    <>
      <Grid container justifyContent="end" alignItems="start">
        <Grid item xs={"auto"} pt={"4px"} pr={3}>
          <Typography className="label-input" style={{ textAlign: "end" }}>
            สถานะ
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          {isView ? (
            <Typography className={status ? "text-active" : "text-inactive"} style={{ fontSize: "22px", paddingTop: "4px", paddingRight: "30px" }}>
              {status ? "Active" : "InActive"}
            </Typography>
          ) : (
            <Switch handleSwitch={handleActive} value={status} />
          )}
        </Grid>
      </Grid>
      <Paper elevation={4} sx={{ borderRadius: 5, mt: 2, pb: 5 }}>
        <Grid container justifyContent="center" alignItems="center" pt={2}>
          <Grid container justifyContent={"center"} item xs={10} sm={10} md={8} lg={5} xl={4} pt={1}>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="400px" sx={{ border: "1px solid #d6d6d6" }}>
              <img src={image || defaultImg} alt="img" loading="lazy" width={image ? "100%" : ""} height={image ? "400px" : ""} />
            </Box>
          </Grid>
        </Grid>
        <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input" sx={{ fontWeight: "bold" }}>
                อัพโหลดรูปภาพ
              </Typography>
              <FormHelperText>ชนิดไฟล์ jpg,png,jpeg ขนาด 1000*500</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
              <FileInput name="file" handleFile={handleFile} fileName={fileName} ref={ref} disabled={isView} accept="image/png, image/jpg, image/jpeg" />
            </Grid>
            <Grid item xs={11} sm={11} md={3} lg={2} xl={1}>
              {isView ? (
                <></>
              ) : (
                <Button
                  sx={styles.btnRound}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    ref.current?.openFileUpload();
                  }}
                >
                  Browse
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
                ชื่อสินค้า
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาไทย</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
              <FormInput fullWidth size="small" name="nameTh" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาอังกฤษ</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
              <FormInput fullWidth size="small" name="nameEn" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
                รายละเอียด
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาไทย</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <FormInput fullWidth multiline rows={8} size="small" name="detailTh" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          <Grid container justifyContent="start" alignItems="start" pl={5}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
              <Typography className="label-input">ภาษาอังกฤษ</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
              <FormInput fullWidth multiline rows={8} size="small" name="detailEn" sx={{ mb: "1.5rem" }} disabled={isView} />
            </Grid>
          </Grid>
          {isView ? (
            <></>
          ) : (
            <Grid container justifyContent="center" pt={2}>
              <Link to={backToPath} style={{ textDecoration: "none" }}>
                <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
                  ยกเลิก
                </Button>
              </Link>
              <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
                บันทึก
              </Button>
            </Grid>
          )}
        </form>
      </Paper>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
