import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function SelectLabels(props: any) {
  const [data, setData] = React.useState(props.value || "");
  const handleChange = (event: SelectChangeEvent) => {
    setData(event.target.value as string);
    props.onChange(event.target.value);
  };
  React.useEffect(() => {
    setData(props.value);
  }, [props]);
  const clearData = () => {
    setData("");
    props.onChange("");
  };
  return (
    <div>
      <FormControl fullWidth error={props.validate}>
        {props.placeholder ? <InputLabel id="demo-simple-select-label">{props.placeholder}</InputLabel> : null}

        <Select
          className={props.className}
          name={props.name}
          value={data}
          onChange={handleChange}
          sx={{
            height: "45px",
            borderRadius: props.rounded ? "30px" : "",
            "& .MuiSelect-iconOutlined": { display: data ? "none" : "" },
          }}
          IconComponent={(props) => <KeyboardArrowDownIcon {...props} color="primary" fontSize="large" />}
          label={props.placeholder ? props.placeholder : ""}
          endAdornment={
            <IconButton sx={{ display: data ? "" : "none" }} onClick={clearData}>
              <CloseIcon color="primary" />
            </IconButton>
          }
        >
          <MenuItem value="" disabled>
            <em>กรุณาเลือก{props.nameth || ""}</em>
          </MenuItem>
          {props.datasource.map((item: any, index: number) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
        {props.validate ? (
          <>
            <FormHelperText>{`กรุณาเลือก${props.nameth || props.name}`}</FormHelperText>
          </>
        ) : (
          <></>
        )}
      </FormControl>
    </div>
  );
}
