import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/contact`);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_general_data");
    const formData = new FormData();
    formData.append("companyNameTh", data.companyNameTh);
    formData.append("companyNameEn", data.companyNameEn);
    formData.append("addressTh", data.addressTh);
    formData.append("addressEn", data.addressEn);

    formData.append("tel", data.tel);
    formData.append("fax", data.fax);
    formData.append("email", data.email);
    formData.append("facebookLink", data.facebookLink);
    formData.append("lineId", data.lineId);
    formData.append("long", data.long);
    formData.append("lat", data.lat);
    return httpService.patch<Response>(`admin/contact`, formData);
  }
}

export const contactService = new Services();
