import Navbar from "pages/Layout/Navbar/Navbar";
import { sidebar } from "pages/Layout/SidebarMenu/sidebar";
import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { selectRootAdmin } from "store/reducer/appReducer";
import { authService } from "services/authentication/auth.service";
import { Response } from "interfaces/utils/utils.interface";

export default function Layout() {
  const isRootAdmin = useSelector(selectRootAdmin);
  const [adminMenu, setAdminMenu] = useState<any>();
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getProfile = async () => {
    if (!isRootAdmin) {
      await authService.getUserProfile().then(async (resp: Response) => {
        const data = resp.data.data ?? resp.data;
        const adminPosition = data.adminPosition;
        const systemUses = adminPosition.adminSystemUses;
        let menu: any = [];
        sidebar.admin.map((item: any) => {
          const adminSystemUses = systemUses.find((sys: any) => item.code === sys.code);
          if (adminSystemUses) {
            menu.push(item);
          }
          return item;
        });
        setAdminMenu(menu);
      });
    }
  };
  return <Navbar listMenu={isRootAdmin ? sidebar.root : adminMenu || []} />;
}
