const root = [
  {
    name: "จัดการผู้ดูแลระบบ",
    child: [
      { name: "จัดการตำแหน่งและสิทธิ์การ ใช้งานระบบ", path: "/admin/role" },
      { name: "จัดการข้อมูลผู้ดูแลระบบ", path: "/admin/information" },
    ],
  },
  {
    name: "Log การใช้งานของผู้ดูแลระบบ",
    path: "/admin/log",
  },
];
const admin = [
  {
    name: "จัดการแบนเนอร์",
    path: "/banner",
    code: "BANNER",
  },
  {
    name: "จัดการข้อมูลเกี่ยวกับเรา",
    path: "/about_us",
    code: "ABOUT_US",
  },
  {
    name: "จัดการ Certificate",
    path: "/certificate",
    code: "CERTIFICATE",
  },
  {
    name: "จัดการข้อมูลคุณภาพโรงงาน",
    path: "/quality",
    code: "FACTORY_QUALITY",
  },
  {
    name: "จัดการข้อมูลการขนส่ง",
    path: "/transport",
    code: "TRANSPORTATION",
  },
  {
    name: "จัดการข้อมูลการควบคุมเครื่องจักร",
    path: "/machine",
    code: "MACHINE_CONTROL",
  },
  {
    name: "จัดการสินค้าและบริการ",
    path: "/goods/services",
    code: "PRODUCT_SERVICE",
  },
  {
    name: "จัดการกิจกรรม",
    path: "/activity",
    code: "ACTIVITY",
  },
  {
    name: "จัดการข้อมูล SDG's",
    path: "/sdgs",
    code: "SDG",
  },
  {
    name: "จัดการโลโก้ลูกค้า",
    path: "/logo",
    code: "CUSTOMER_LOGO",
  },
  {
    name: "จัดการตำแหน่งงานรับสมัคร",
    path: "/job/opening",
    code: "POSITION",
  },
  {
    name: "ดูรายการสมัครงาน",
    path: "/job/list",
    code: "JOB_APPLICATION",
  },
  {
    name: "จัดการข้อมูลทั่วไป",
    code: "GENERAL_DATA",
    child: [
      { name: "นโยบายความเป็นส่วนตัว", path: "/general/policy" },
      { name: "ติดต่อเรา", path: "/general/contact_us" },
    ],
  },
  {
    name: "จัดการข้อมูล Careers",
    path: "/careers",
    code: "CAREER",
  }
];
export const sidebar = {
  admin,
  root,
};
