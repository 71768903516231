// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";

// import { selectRootAdmin, setProfile, setTitle } from "store/reducer/appReducer";
// import { authService } from "services/authentication/auth.service";
// import { Response } from "interfaces/utils/utils.interface";
export default function UserManagement() {
  // const dispatch = useDispatch();
  // const isRootAdmin = useSelector(selectRootAdmin);
  // useEffect(() => {
  //   dispatch(setTitle(""));
  //   getProfile();
  // });
  // const getProfile = async () => {
  //   if (isRootAdmin) {
  //     await authService.getRootProfile().then(async (item: Response) => {
  //       const data = item.data.data ?? item.data;
  //       dispatch(setProfile(data));
  //     });
  //   } else {
  //     await authService.getUserProfile().then(async (item: Response) => {
  //       const data = item.data.data ?? item.data;
  //       dispatch(setProfile(data));
  //     });
  //   }
  // };
  return <></>;
}
