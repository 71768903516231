import { Button, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setTitle } from "store/reducer/appReducer";
import { SelectChangeEvent } from "@mui/material/Select";
import PageSize from "components/PageSize";
import Pagination from "components/Pagination";
import { adminService } from "services/admin/admin.service";

import IconBin from "assets/icon/ic_bin.png";
import IconEdit from "assets/icon/ic_edit.png";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import ModalConfirm from "components/DialogConfirm";
import Modal from "components/Dialog";
import { IModal } from "interfaces/utils/utils.interface";

import CustomTableCell from "components/CustomTableCell";
import CustomTableRow from "components/CustomTableRow";
import { styles } from "assets/styles";
import { defaultModal, defaultModalDelete, deleteFail, deleteSuccess, setHeader } from "helpers/utility";

const headers = setHeader([
  { name: "ID", align: "center", width: "10%" },
  { name: "ชื่อ", align: "center" },
  { name: " นามสกุล", align: "center" },
  { name: "ตำแหน่ง", align: "center" },
  { name: "อีเมล", align: "center" },
  { name: "เบอร์โทรศัพท์", align: "center" },
  { name: "จัดการ", align: "center", width: "30%" },
]);

export default function UserManagement() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [pageSize, setPageSize] = useState("5");
  const [pageCount, setPageCount] = useState("0");
  const [dataTable, setDataTable] = useState<any>([]);
  const [modalConfirm, setModalConfirm] = useState<IModal>(defaultModal);
  const [modal, setModal] = useState<IModal>(defaultModal);
  const configModal = (deleteId: number) => {
    setDeleteId(deleteId);
    setModalConfirm(defaultModalDelete);
  };
  const searchUser = async () => {
    await adminService.searchUser("", String(page), pageSize).then((item: any) => {
      const data = item.data.data ?? item.data;
      if (item.data.count !== 0) {
        setPageCount(item.data.count || 1);
      }
      data.map((item: any) => {
        item.role = item.adminPosition?.name ?? "";
        return item;
      });
      setDataTable(data);
    });
  };
  const deleteUser = async (id: number) => {
    await adminService
      .deleteUser(String(id))
      .then(() => {
        setModal(deleteSuccess);
        searchUser();
      })
      .catch(() => {
        setModal(deleteFail);
      });
  };
  useEffect(() => {
    dispatch(setTitle("จัดการข้อมูลผู้ดูแลระบบ"));

    searchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  const handleChange = (event: SelectChangeEvent) => setPageSize(event.target.value as string);
  const handleChangePage = (value: any) => setPage(value);
  const handleModalConfirm = (confirm: any) => {
    if (confirm === true) {
      deleteUser(deleteId);
    }
    setDeleteId(null);
    setModalConfirm({
      ...modalConfirm,
      open: false,
    });
  };
  const handleModal = (confirm: any) => {
    setModal({ ...modal, open: confirm });
  };
  return (
    <>
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: handleModal,
        }}
      />
      <ModalConfirm
        props={{
          modalOpen: modalConfirm.open,
          modalStatus: modalConfirm.status,
          modalMessage: modalConfirm.message,
          handleModal: handleModalConfirm,
        }}
      />
      <Grid container alignContent="center" justifyContent="end" mb={3}>
        <Grid item xs={4} sm={6} md={4} lg={3} xl={2}>
          <Link to="create" style={{ textDecoration: "none" }}>
            <Button sx={styles.btnRound} variant="contained" color="primary" type="submit" startIcon={<AddIcon></AddIcon>}>
              เพิ่มผู้ดูแลระบบ
            </Button>
          </Link>
        </Grid>
      </Grid>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead className="table-header">
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>
            {dataTable.length > 0 ? (
              dataTable.map((row: any) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell align="center">{String(row.id).padStart(3, "0")}</CustomTableCell>
                  <CustomTableCell align="center">{`${row.firstname}`}</CustomTableCell>
                  <CustomTableCell align="center">{`${row.lastname}`}</CustomTableCell>
                  <CustomTableCell align="center">{`${row.role}`}</CustomTableCell>
                  <CustomTableCell align="center">{row.email}</CustomTableCell>
                  <CustomTableCell align="center">{row.tel}</CustomTableCell>
                  <CustomTableCell
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Link to={`edit/${row.id}`}>
                      <Button variant="text" style={styles.btnAction}>
                        <img src={IconEdit} alt="details" width="100%" />
                      </Button>
                    </Link>
                    <Button variant="text" style={styles.btnAction} onClick={() => configModal(row.id)}>
                      <img src={IconBin} alt="details" width="100%" />
                    </Button>
                  </CustomTableCell>
                </CustomTableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination count={parseInt(`${Math.ceil(parseInt(pageCount) / parseInt(pageSize))}`)} page={page} handleChange={handleChangePage} />
      <PageSize pageSize={pageSize} page={page} count={pageCount} handleChange={handleChange}></PageSize>
    </>
  );
}
