import { Link, useLocation } from "react-router-dom";
import { setTitleWithBack } from "store/reducer/appReducer";
import { useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { saveSuccess, editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import Switch from "components/Switch";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import FormInput from "components/FormInput";
import { jobOpeningService } from "services/user/jobOpening.service";
import { errorMsg } from "helpers/helper";
const Schema = object({
  nameTh: string().min(1, "กรุณาระบุหัวข้อภาษาไทย"),
  nameEn: string().min(1, "กรุณาระบุหัวข้อภาษาอังกฤษ"),
  amount: string()
    .min(1, "กรุณาระบุจำนวนที่รับสมัคร")
    .regex(/^[0-9]*$/, "กรุณาระบุจำนวนเป็นตัวเลขเท่านั้น"),
  detailTh: string().min(1, "กรุณาระบุรายละเอียดภาษาไทย"),
  detailEn: string().min(1, "กรุณาระบุรายละเอียดภาษาอังกฤษ"),

  qualificationTh: string().min(1, "กรุณาระบุคุณสมบัติภาษาไทย"),
  qualificationEn: string().min(1, "กรุณาระบุคุณสมบัติอังกฤษ"),
  welfareTh: string().min(1, "กรุณาระบุสวัสดิการภาษาไทย"),
  welfareEn: string().min(1, "กรุณาระบุสวัสดิการอังกฤษ"),
});
type ISchema = TypeOf<typeof Schema>;
export default function JobOpeningForm() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const defaultValues: ISchema = {
    nameTh: "",
    nameEn: "",
    amount: "",
    detailTh: "",
    detailEn: "",
    qualificationTh: "",
    qualificationEn: "",
    welfareTh: "",
    welfareEn: "",
  };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const location = useLocation();
  const backToPath = "/job/opening";
  const isCreate = location.pathname.search("create") !== -1;
  const isEdit = location.pathname.search("edit") !== -1;
  const isView = location.pathname.search("detail") !== -1;
  const getById = async () => {
    await jobOpeningService.getById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      setStatus(data.isActive);

      methods.setValue("nameTh", data.nameTh);
      methods.setValue("nameEn", data.nameEn);
      methods.setValue("detailTh", data.detailTh);
      methods.setValue("detailEn", data.detailEn);
      methods.setValue("qualificationTh", data.qualificationTh);
      methods.setValue("qualificationEn", data.qualificationEn);
      methods.setValue("welfareTh", data.welfareTh);
      methods.setValue("welfareEn", data.welfareEn);
      methods.setValue("amount", String(data.amount));
    });
  };
  useEffect(() => {
    if (isView) {
      getById();
      dispatch(setTitleWithBack({ title: "ดูรายละเอียดตำแหน่งงานที่รับสมัคร", path: backToPath }));
    } else if (isEdit) {
      getById();
      dispatch(setTitleWithBack({ title: "แก้ไขตำแหน่งงานที่รับสมัคร", path: backToPath }));
    } else dispatch(setTitleWithBack({ title: "เพิ่มตำแหน่งงานที่รับสมัคร", path: backToPath }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    const data = {
      id: parseInt(params.id || ""),
      nameTh: values.nameTh,
      nameEn: values.nameEn,
      detailTh: values.detailTh,
      detailEn: values.detailEn,
      qualificationTh: values.qualificationTh,
      qualificationEn: values.qualificationEn,
      welfareTh: values.welfareTh,
      welfareEn: values.welfareEn,
      amount: values.amount,
      isActive: status,
    };
    setLoading(true);
    if (isCreate) {
      await jobOpeningService
        .create(data)
        .then(() => {
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      await jobOpeningService
        .update(data)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
    }

    setLoading(false);
  };
  const handleActive = (isActive: boolean) => {
    setStatus(isActive);
    if (!isCreate) active(isActive, params.id);
  };
  const active = async (status: boolean, id: any) => {
    const data = {
      id: id,
      isActive: status,
    };
    await jobOpeningService.updateActive(data).then();
  };
  const form = (
    <>
      <Grid container justifyContent="end" alignItems="start">
        <Grid item xs={"auto"} pt={"4px"} pr={3}>
          <Typography className="label-input" style={{ textAlign: "end" }}>
            สถานะ
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          {isView ? (
            <Typography className={status ? "text-active" : "text-inactive"} style={{ fontSize: "22px", paddingTop: "4px", paddingRight: "30px" }}>
              {status ? "Active" : "InActive"}
            </Typography>
          ) : (
            <Switch handleSwitch={handleActive} value={status} />
          )}
        </Grid>
      </Grid>

      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              ชื่อตำแหน่งงานที่รับสมัคร
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FormInput fullWidth size="small" name="nameTh" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FormInput fullWidth size="small" name="nameEn" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>

        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              จำนวนที่รับ
            </Typography>
          </Grid>
          <Grid item xs={5} sm={4} md={3} lg={2} xl={1} mr={3}>
            <FormInput fullWidth size="small" name="amount" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
          <Grid item xs={"auto"} pt={1}>
            <Typography className="label-input" sx={{ color: "#999" }}>
              อัตรา
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              รายละเอียดงาน
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={3}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput fullWidth multiline rows={5} size="small" name="detailTh" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput fullWidth multiline rows={5} size="small" name="detailEn" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>

        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              คุณสมบัติ
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={3}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput fullWidth multiline rows={10} size="small" name="qualificationTh" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput fullWidth multiline rows={10} size="small" name="qualificationEn" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>

        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              สวัสดิการ
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={3}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput fullWidth multiline rows={5} size="small" name="welfareTh" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput fullWidth multiline rows={5} size="small" name="welfareEn" sx={{ mb: "1.5rem" }} disabled={isView} />
          </Grid>
        </Grid>

        {isView ? (
          <></>
        ) : (
          <Grid container justifyContent="center" pt={2}>
            <Link to={backToPath} style={{ textDecoration: "none" }}>
              <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
                ยกเลิก
              </Button>
            </Link>
            <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
              บันทึก
            </Button>
          </Grid>
        )}
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
