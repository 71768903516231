import { Navigate, useRoutes } from "react-router-dom";

import { Auth } from "pages/authentication/index";
import { Admin as Root } from "pages/admin/index";
import { User as Admin } from "pages/user/index";
import About from "pages/about";
import Layout from "pages/Layout/Layout";
import AuthLayout from "pages/Layout/AuthLayout";

import Profile from "pages/profile/form";
import ChangePassword from "pages/changePassword/form";

function PrivateRoute() {
  let element = useRoutes([
    {
      element: <Layout />,
      children: [
        { path: "/", element: <About /> },
        { path: "/profile", element: <Profile /> },
        { path: "/change-password", element: <ChangePassword /> },

        { path: "/admin/information", element: <Root.Information /> },
        { path: "/admin/information/create", element: <Root.InformationForm /> },
        { path: "/admin/information/edit/:id", element: <Root.InformationForm /> },

        { path: "/admin/role", element: <Root.Role /> },
        { path: "/admin/role/create", element: <Root.RoleForm /> },
        { path: "/admin/role/edit/:id", element: <Root.RoleForm /> },
        { path: "/admin/log", element: <Root.Log /> },

        { path: "/banner", element: <Admin.Banner /> },
        { path: "/banner/create", element: <Admin.BannerForm /> },
        { path: "/banner/edit/:id", element: <Admin.BannerForm /> },

        { path: "/about_us", element: <Admin.AboutUs /> },
        { path: "/careers", element: <Admin.Careers /> },

        { path: "/goods/services", element: <Admin.GoodsServices /> },
        { path: "/goods/services/create", element: <Admin.GoodsServicesForm /> },
        { path: "/goods/services/edit/:id", element: <Admin.GoodsServicesForm /> },
        { path: "/goods/services/detail/:id", element: <Admin.GoodsServicesForm /> },

        { path: "/quality", element: <Admin.Quality /> },
        { path: "/quality/create", element: <Admin.QualityForm /> },
        { path: "/quality/edit/:id", element: <Admin.QualityForm /> },
        { path: "/quality/detail/:id", element: <Admin.QualityForm /> },

        { path: "/machine", element: <Admin.Machine /> },
        { path: "/machine/create", element: <Admin.MachineForm /> },
        { path: "/machine/edit/:id", element: <Admin.MachineForm /> },
        { path: "/machine/detail/:id", element: <Admin.MachineForm /> },

        { path: "/transport", element: <Admin.Transport /> },
        { path: "/transport/create", element: <Admin.TransportForm /> },
        { path: "/transport/edit/:id", element: <Admin.TransportForm /> },
        { path: "/transport/detail/:id", element: <Admin.TransportForm /> },

        { path: "/sdgs", element: <Admin.SDGS /> },
        { path: "/sdgs/create", element: <Admin.SDGSForm /> },
        { path: "/sdgs/edit/:id", element: <Admin.SDGSForm /> },
        { path: "/sdgs/detail/:id", element: <Admin.SDGSForm /> },

        { path: "/certificate", element: <Admin.Certificate /> },
        { path: "/certificate/create", element: <Admin.CertificateForm /> },
        { path: "/certificate/edit/:id", element: <Admin.CertificateForm /> },
        { path: "/certificate/detail/:id", element: <Admin.CertificateForm /> },

        { path: "/activity", element: <Admin.Activity /> },
        { path: "/activity/create", element: <Admin.ActivityForm /> },
        { path: "/activity/edit/:id", element: <Admin.ActivityForm /> },
        { path: "/activity/detail/:id", element: <Admin.ActivityForm /> },

        { path: "/logo", element: <Admin.Logo /> },
        { path: "/logo/create", element: <Admin.LogoForm /> },
        { path: "/logo/edit/:id", element: <Admin.LogoForm /> },

        { path: "/job/opening", element: <Admin.JobOpening /> },
        { path: "/job/opening/create", element: <Admin.JobOpeningForm /> },
        { path: "/job/opening/edit/:id", element: <Admin.JobOpeningForm /> },
        { path: "/job/opening/detail/:id", element: <Admin.JobOpeningForm /> },

        { path: "/job/list", element: <Admin.JobList /> },
        { path: "/job/list/detail/:id", element: <Admin.JobListForm /> },

        { path: "/general/policy", element: <Admin.Policy /> },
        { path: "/general/contact_us", element: <Admin.ContactUs /> },

        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
  ]);

  return element;
}

function AuthRoute() {
  let element = useRoutes([
    {
      element: <AuthLayout />,
      children: [
        { path: "/", element: <Auth.Login /> },
        { path: "/login", element: <Auth.Login /> },
        { path: "/forgot-password", element: <Auth.ForgotPassword /> },
        { path: "/change-password/*", element: <Auth.ChangePassword /> },
        { path: "*", element: <Navigate to="/" replace /> },
      ],
    },
  ]);

  return element;
}

export const Render = {
  PrivateRoute,
  AuthRoute,
};
