import { useDispatch } from "react-redux";
import { setTitle } from "store/reducer/appReducer";
import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import FormInput from "components/FormInput";
import { contactService } from "services/user/contactus.service";
import { errorMsg } from "helpers/helper";
const Schema = object({
  companyNameTh: string().min(1, "กรุณาระบุชื่อบริษัทภาษาไทย"),
  companyNameEn: string().min(1, "กรุณาระบุชื่อบริษัทภาษาอังกฤษ"),
  addressTh: string().min(1, "กรุณาระบุที่อยู่ภาษาไทย"),
  addressEn: string().min(1, "กรุณาระบุที่อยู่ภาษาอังกฤษ"),
  tel: string()
    .regex(/^[0-9]*$/, "กรุณาระบุหมายเลขโทรศัพท์เป็นตัวเลขเท่านั้น")
    .min(9, "กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว")
    .max(10, "กรุณาระบุเบอร์โทรศัพท์ไม่เกิน 10 ตัว"),
  fax: string().min(1, "กรุณาระบุชื่อบริษัทภาษาอังกฤษ"),
  email: string().min(1, "กรุณาระบุอีเมล").email("รูปแบบอีเมลไม่ถูกต้อง"),

  facebookLink: string().min(0, "กรุณาระบุ Facebook Link"),
  lineId: string().min(0, "กรุณาระบุ Line ID"),
  lat: string().min(1, "กรุณาระบุ Latitude"),
  lng: string().min(1, "กรุณาระบุ Longitude"),
});
type ISchema = TypeOf<typeof Schema>;
export default function ContactUs() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = {
    companyNameTh: "",
    companyNameEn: "",
    addressTh: "",
    addressEn: "",
    tel: "",
    email: "",
    fax: "",
    facebookLink: "",
    lineId: "",
    lat: "",
    lng: "",
  };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const get = async () => {
    await contactService.get().then((item: any) => {
      const data = item.data.data ?? item.data;

      methods.setValue("companyNameTh", data.companyNameTh);
      methods.setValue("companyNameEn", data.companyNameEn);
      methods.setValue("addressTh", data.addressTh);
      methods.setValue("addressEn", data.addressEn);
      methods.setValue("email", data.email);
      methods.setValue("tel", data.tel);
      methods.setValue("fax", data.fax);
      methods.setValue("facebookLink", data.facebookLink);
      methods.setValue("lineId", data.lineId);
      methods.setValue("lat", data.lat);
      methods.setValue("lng", data.long);
    });
  };
  useEffect(() => {
    dispatch(setTitle("จัดการข้อมูลติดต่อเรา"));
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    const data = {
      companyNameTh: values.companyNameTh,
      companyNameEn: values.companyNameEn,
      addressTh: values.addressTh,
      addressEn: values.addressEn,
      email: values.email,
      tel: values.tel,
      fax: values.fax,
      facebookLink: values.facebookLink,
      lineId: values.lineId,
      lat: values.lat,
      long: values.lng,
    };
    setLoading(true);

    await contactService
      .update(data)
      .then(() => {
        setModal(editSuccess);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          setModal({
            open: true,
            message: errorMsg(err),
            status: "error",
          });
        } else {
          setModal(somethingWrong);
        }
      });

    setLoading(false);
  };
  const form = (
    <>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              ชื่อบริษัท
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="companyNameTh" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="companyNameEn" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>

        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              ที่อยู่
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput multiline rows={4} fullWidth size="small" name="addressTh" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput multiline rows={4} fullWidth size="small" name="addressEn" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">อีเมล</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="email" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">เบอร์โทรศัพท์</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="tel" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">โทรสาร</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="fax" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">Facebook Link</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="facebookLink" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">Line ID</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={7}>
            <FormInput fullWidth size="small" name="lineId" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">Location</Typography>
          </Grid>
          <Grid container item xs={12} sm={12} md={8} lg={8} xl={7} justifyContent="space-between">
            <Grid item xs={6} pr={3}>
              <FormInput fullWidth size="small" name="lat" sx={{ mb: "1.5rem" }} />
            </Grid>
            <Grid item xs={6}>
              <FormInput fullWidth size="small" name="lng" sx={{ mb: "1.5rem" }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pt={2}>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
