import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/policy`);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_general_data");
    const formData = new FormData();
    formData.append("detailTh", data.detailTh);
    formData.append("detailEn", data.detailEn);
    return httpService.patch<Response>(`admin/policy`, formData);
  }
}

export const policyService = new Services();
