export function errorMsg(error: any) {
  const err = error.response?.data?.errors;

  const keyMsg = listKey.find((item) => {
    return err.includes(item.key) ? item.value : undefined;
  })?.value;

  const keywordMsg = listKeyword.find((item) => {
    return err.includes(item.keyword) ? item.value : undefined;
  })?.value;

  if (String(keyMsg) === "false" || String(keywordMsg) === "false") {
    return error.message;
  } else return `${keywordMsg} ${keyMsg}`;
}

const listKeyword = [
  { keyword: "`email`", value: "อีเมล" },
  { keyword: "`tel`", value: "เบอร์โทรศัพท์" },
  { keyword: "`name`", value: "ชื่อ" },
];

const listKey = [{ key: "duplicate", value: "นี้มีข้อมูลซ้ำกันในระบบ" }];
