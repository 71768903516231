import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { selectIsLogged } from "store/reducer/appReducer";
import * as Routes from "./routes/index";
export default function App() {
  const loggedIn = useSelector(selectIsLogged);
  if (loggedIn) {
    return (
      <BrowserRouter>
        <Routes.Render.PrivateRoute />
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes.Render.AuthRoute />
      </BrowserRouter>
    );
  }
}
