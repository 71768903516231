import { useDispatch } from "react-redux";
import { setTitle } from "store/reducer/appReducer";
import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import FileInput from "components/FileInput";
import Switch from "components/Switch";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import FormInput from "components/FormInput";
import { CareerService } from "services/user/career.service";
import { errorMsg } from "helpers/helper";
const Schema = object({
  file: string().min(1, "กรุณาเลือกไฟล์"),
  titleTh: string().min(1, "กรุณาระบุหัวข้อภาษาไทย"),
  titleEn: string().min(1, "กรุณาระบุหัวข้อภาษาอังกฤษ"),
  detailTh: string().min(1, "กรุณาระบุรายละเอียดภาษาไทย"),
  detailEn: string().min(1, "กรุณาระบุรายละเอียดภาษาอังกฤษ"),
});
type ISchema = TypeOf<typeof Schema>;
export default function AboutUs() {
  const ref = useRef<any>();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
  const [fileName, setFileName] = useState("");
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = { file: "", titleTh: "", titleEn: "", detailTh: "", detailEn: "" };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const [video, setVideo] = useState<any>();
  const [file, setFile] = useState<any>();
  const get = async () => {
    await CareerService.get().then((item: any) => {
      const data = item.data.data ?? item.data;
      methods.setValue("file", data.name);
      setFileName(data.name);
      setStatus(data.isActive);
      setVideo(data.video);
      methods.setValue("titleTh", data.titleTh);
      methods.setValue("titleEn", data.titleEn);
      methods.setValue("detailTh", data.detailTh);
      methods.setValue("detailEn", data.detailEn);
    });
  };
  useEffect(() => {
    dispatch(setTitle("จัดการข้อมูล Careers"));
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    setLoading(true);
    const data = {
      file: file,
      titleTh: values.titleTh,
      titleEn: values.titleEn,
      detailTh: values.detailTh,
      detailEn: values.detailEn,
    };

    await CareerService
      .update(data)
      .then(() => {
        setModal({ ...editSuccess });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          if (err.response?.data?.errors === "error, file extension out of scope [.mp4]") {
            setModal({
              open: true,
              message: "กรุณาอัพโหลดไฟล์นามสกุล .mp4",
              status: "error",
            });
          } else {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          }
        } else {
          setModal(somethingWrong);
        }
      });

    setLoading(false);
  };
  const handleFile = (item: any) => {
    methods.clearErrors("file");
    if (item) {
      methods.setValue("file", item.name);
      setFileName(item.name);
      setVideo(URL.createObjectURL(item));
      setFile(item);
    } else {
      methods.setValue("file", "");
      setFileName("");
      setVideo(null);
      setFile(null);
    }
  };

  const handleActive = (isActive: boolean) => {
    setStatus(isActive);
    active(isActive);
  };
  const active = async (status: boolean) => {
    const data = {
      isActive: status,
    };
    await CareerService.updateActive(data).then();
  };
  const form = (
    <>
      <Grid container justifyContent="end" alignItems="start">
        <Grid item xs={"auto"} pt={"4px"} pr={3}>
          <Typography className="label-input" style={{ textAlign: "end" }}>
            สถานะ
          </Typography>
        </Grid>
        <Grid item xs={"auto"}>
          <Switch handleSwitch={handleActive} value={status} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" pt={2}>
        <Grid item xs={12} sm={12} md={10} lg={9} xl={7} pt={1}>
          <video controls width="100%" src={video}>
            Sorry, your browser doesn't support videos.
          </video>
        </Grid>
      </Grid>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ fontWeight: "bold" }}>
              อัพโหลดไฟล์วิดีโอ
            </Typography>
            <FormHelperText>ชนิดไฟล์ .mp4</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FileInput name="file" handleFile={handleFile} fileName={fileName} ref={ref} accept="video/mp4" />
          </Grid>
          <Grid item xs={11} sm={11} md={3} lg={2} xl={1}>
            <Button
              sx={styles.btnRound}
              variant="contained"
              color="primary"
              onClick={() => {
                ref.current?.openFileUpload();
              }}
            >
              Browse
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              หัวข้อ
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FormInput fullWidth size="small" name="titleTh" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7} pr={4}>
            <FormInput fullWidth size="small" name="titleEn" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              รายละเอียด
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" px={5}>
          <Grid item xs={12}>
            <FormInput fullWidth multiline rows={12} size="small" name="detailTh" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" px={5} pt={2}>
          <Grid item xs={12}>
            <FormInput fullWidth multiline rows={12} size="small" name="detailEn" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pt={2}>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
