import { Link } from "react-router-dom";
import { setTitleWithBack } from "store/reducer/appReducer";
import { useDispatch } from "react-redux";
import { Button, Checkbox, FormHelperText, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { useParams } from "react-router-dom";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import DownloadIcon from "assets/icon/ic_download.png";
import { jobListService } from "services/user/jobList.service";
import moment from "moment";
import { errorMsg } from "helpers/helper";

export default function JobListForm() {
  const backToPath = "/job/list";
  const params = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [job, setJob] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [document, setDocument] = useState<any>();
  const [status, setStatus] = useState<any>();

  const [success, setSuccess] = useState<any>(false);

  const [approvedAt, setApprovedAt] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [valid, setValid] = useState(false);
  const getById = async () => {
    await jobListService.getById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      setStatus(data.isActive);

      setName(`${data.firstname} ${data.lastname}`);
      setDate(moment(data.createdAt).format("DD/MM/YYYY HH:mm") || "");
      setJob(data.jobPosition?.nameTh || "");
      setEmail(data.email);
      setTel(data.tel);
      setDocument(data.userDocuments);

      setStatus(data.jobStatus);
      setSuccess(data.jobStatus === "approved" ? true : false);

      setApproved(data.jobStatus === "approved" ? true : false);
      setApprovedAt(data.approvedAt ? moment(data.approvedAt).format("DD/MM/YYYY HH:mm") : "");
      setApprovedBy(data.jobStatus === "approved" ? `${data.approvedBy?.firstname} ${data.approvedBy?.lastname}` : "");
    });
  };
  useEffect(() => {
    dispatch(setTitleWithBack({ title: "ดูรายละเอียดการสมัครงาน", path: backToPath }));
    getById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler = async (event: any) => {
    setValid(false);
    event.preventDefault();
    setLoading(true);
    const data = {
      id: parseInt(params.id || ""),
    };
    setLoading(true);
    if (approved === true) {
      await jobListService
        .update(data)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
    } else {
      setValid(true);
      setModal({ open: true, message: "กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก", status: "warning" });
    }

    setLoading(false);
  };

  const handleCheck = (event: any) => {
    setValid(!event.target.checked);
    if (status === "approved") {
      setApproved(true);
      return;
    } else {
      setApproved(event.target.checked);
    }
  };
  const checkStatus = (status: any) => {
    let classes = "";
    let text = "";
    if (status === "pending") {
      classes = "label-input text-wait";
      text = "รอดำเนินการ";
    } else if (status === "approved") {
      classes = "label-input text-active";
      text = "ดำเนินการแล้ว";
    }
    return <Typography className={classes}>{text}</Typography>;
  };

  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <form onSubmit={onSubmitHandler}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
            <Typography className="label-input">ชื่อ-นามสกุลผู้สมัคร</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
            <Typography className="label-input">{name}</Typography>
          </Grid>
          <Grid container item xs={4} sm={12} md={12} lg={4} xl={4} justifyContent="start" alignItems="start">
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Typography className="label-input">วันที่สมัคร</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
              <Typography className="label-input">{date}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
            <Typography className="label-input">ตำแหน่งงานที่สมัคร</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
            <Typography className="label-input">{job}</Typography>
          </Grid>
          <Grid container item xs={4} sm={12} md={12} lg={4} xl={4} justifyContent="start" alignItems="start">
            <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
              <Typography className="label-input">สถานะ</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
              {checkStatus(status)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
            <Typography className="label-input">อีเมล</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
            <Typography className="label-input">{email}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
            <Typography className="label-input">เบอร์โทรศัพท์</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={5} xl={5}>
            <Typography className="label-input">{tel}</Typography>
          </Grid>
        </Grid>
        {document?.map((item: any, index: number) => {
          return (
            <Grid container justifyContent="start" alignItems="start" pl={5} pt={2} key={index}>
              <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
                <Typography className="label-input">{index === 0 ? "เอกสารสมัครงาน" : ""}</Typography>
              </Grid>
              <Grid container item xs={12} sm={12} md={7} lg={5} xl={5}>
                <Grid item xs="auto">
                  <a href={`${item.file}`} download target="_blank" rel="noreferrer" className="label-input">
                    {item.file}
                  </a>
                  <a href={`${item.file}`} download target="_blank" rel="noreferrer" className="label-input" style={{ paddingLeft: "10px" }}>
                    <img src={DownloadIcon} alt="download" width={30} />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Grid container justifyContent="start" alignItems="start" px={5} pt={5}>
          <div className="box box-a"></div>
        </Grid>

        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              เปลี่ยนสถานะรายการรับสมัคร
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="start" alignItems="start" px={5} pt={3}>
          <div>
            <Checkbox
              style={{
                transform: "scale(2)",
                paddingTop: "5px",
              }}
              checked={approved}
              onChange={handleCheck}
              color="success"
            />
            <Typography component={"span"} className="label-input" sx={{ pl: 3 }}>
              ตรวจสอบแล้ว
            </Typography>
          </div>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={13}>
          <Grid item xs={12}>
            <FormHelperText>วันทีตรวจสอบ &nbsp; {approvedAt}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>ชื่อผู้ตรวจสอบ &nbsp; {approvedBy}</FormHelperText>
          </Grid>
        </Grid>
        {valid ? (
          <Grid container justifyContent="start" alignItems="start" px={5} pt={3}>
            <FormHelperText sx={{ color: "red" }}>{`กรุณาตรวจสอบข้อมูลก่อนทำการบันทึก`}</FormHelperText>
          </Grid>
        ) : (
          <></>
        )}
        {success ? (
          <></>
        ) : (
          <Grid container justifyContent="center" pt={2}>
            <Link to={backToPath} style={{ textDecoration: "none" }}>
              <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
                ยกเลิก
              </Button>
            </Link>
            <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
              บันทึก
            </Button>
          </Grid>
        )}
      </form>
    </>
  );
}
