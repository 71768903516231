import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Paper, FormControl, InputAdornment, IconButton, Grid, Button } from "@mui/material";

import Visibility from "assets/icon/ic_eye.svg";
import VisibilityOff from "assets/icon/ic_eye_off.png";

import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "assets/icon/ic_key.png";
import Logo from "assets/logo/ic_bg_small@2x.png";

import Modal from "components/Dialog";
import Loading from "components/Loading";

import { authService } from "services/authentication/auth.service";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { setUser } from "store/reducer/appReducer";
import FormInput from "components/FormInput";

import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styles } from "assets/styles";

import { IModal, Response } from "interfaces/utils/utils.interface";
import { AxiosResponse } from "axios";
import { defaultModal } from "helpers/utility";

interface State {
  email: string;
  password: string;
  showPassword: boolean;
}
const Schema = object({
  email: string().min(1, "กรุณาระบุอีเมล").email("รูปแบบอีเมลไม่ถูกต้อง"),
  password: string().min(1, "กรุณาระบุรหัสผ่าน").min(6, "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร"),
});
type ILogin = TypeOf<typeof Schema>;

export default function Login() {
  const [values, setValues] = useState<State>({
    email: "",
    password: "",
    showPassword: false,
  });
  const [modal, setModal] = useState<IModal>(defaultModal);

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const defaultValues: ILogin = {
    email: values.email,
    password: values.password,
  };
  const methods = useForm<ILogin>({
    resolver: zodResolver(Schema),
    defaultValues,
  });

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmitHandler: SubmitHandler<ILogin> = async (values: ILogin) => {
    setLoading(true);

    await authService
      .login(values.email, values.password)
      .then(async (item: AxiosResponse<Response>) => {
        const data = item.data?.data ?? item.data;
        dispatch(setUser(data));
        navigate(0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 400) {
          setModal({
            open: true,
            message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
            status: "error",
          });
        } else {
          setModal({
            open: true,
            message: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
            status: "error",
          });
        }
      });
  };
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>
        <Grid
          style={styles.paperContainer}
          container
          sx={{
            height: "100%",
          }}
        >
          <Grid
            item
            xs={5}
            sm={12}
            md={5}
            lg={6}
            sx={{
              height: "100vh",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100vh",
              }}
            >
              <img style={{ paddingLeft: "2px", paddingBottom: "5px" }} src={Logo} alt="logo" />
              <div style={styles.txtLeftPadding}>Central Metals (Thailand) Ltd.</div>
            </Grid>
          </Grid>
          <Grid container alignContent="center" justifyContent="center" item xs={12} sm={12} md={7} lg={6} sx={{ pr: 7, pl: 1, height: "100vh" }}>
            <Grid item xs={10} md={10}>
              <Paper elevation={4} square sx={{ height: "70vh", minHeight: "500px" }}>
                <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
                  <Grid
                    container
                    sx={{
                      height: "100%",
                      py: 5,
                    }}
                    justifyContent="center"
                  >
                    <Grid item xs={8}>
                      <Grid container justifyContent="center" item sx={{ pb: 10, pt: 5 }}>
                        <div
                          style={{
                            fontSize: "37px",
                          }}
                        >
                          <label>เข้าสู่ระบบ</label>
                        </div>
                      </Grid>
                      <Grid item>
                        <FormControl fullWidth variant="outlined">
                          <FormInput
                            variant="standard"
                            placeholder="อีเมล"
                            type="email"
                            name="email"
                            sx={{ mb: 5 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl fullWidth variant="outlined">
                          <FormInput
                            variant="standard"
                            name="password"
                            placeholder="รหัสผ่าน"
                            id="outlined-adornment-password"
                            type={values.showPassword ? "text" : "password"}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img style={{ paddingLeft: "2px" }} src={LockIcon} alt="iconLock" width="70%" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {values.showPassword ? (
                                      <img src={Visibility} alt="Visibility" width="80%" />
                                    ) : (
                                      <img src={VisibilityOff} alt="VisibilityOff" width="80%" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container justifyContent="end" item style={styles.forgotMargin}>
                        <Link to="/forgot-password" style={{ color: "black", fontWeight: "bold" }}>
                          ลืมรหัสผ่าน ?
                        </Link>
                      </Grid>
                      <Grid item container justifyContent="center">
                        <Button style={styles.btnConfirm} className="btn-grad" variant="contained" color="primary" type="submit">
                          เข้าสู่ระบบ
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
