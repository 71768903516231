import { FC, forwardRef, useImperativeHandle, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};
const CssTextField = styled(TextField)({
  "& .MuiInputBase-root.Mui-disabled > input:disabled": {
    WebkitTextFillColor: "rgba(0, 0, 0, 1)",
  },
  "& label.Mui-focused": {
    fontWeight: 400,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#d32f2f",
      },
    },
    "& fieldset": {
      borderRadius: 5,
      backgroundColor: "transparent",
    },
  },
});
const FormInput: FC<any> = forwardRef((props: any, parentRefs) => {
  const ref = useRef<any>();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const onInputChange = (e: any) => {
    // const target = e.target as HTMLInputElement;
    const file = e.target.files[0];
    if (file) {
      props.handleFile(file);
    } else {
      props.handleFile("");
    }
  };
  useImperativeHandle(parentRefs, () => ({
    openFileUpload() {
      ref.current?.click();
    },
  }));
  return (
    <>
      <input ref={ref} id="import-button" onChange={onInputChange} style={styles.hidden} type="file" disabled={props.disabled} accept={props.accept} />
      <Controller
        control={control}
        name={props.name}
        defaultValue=""
        render={({ field }) => (
          <CssTextField
            onClick={() => {
              ref.current?.click();
            }}
            disabled
            inputProps={{
              style: {
                height: "30px",
              },
            }}
            fullWidth
            size="small"
            sx={{ mb: "1.5rem", bgcolor: props.disabled ? "#f5f6fa" : "" }}
            {...field}
            // {...props}
            value={props.fileName}
            error={!!errors[props.name]}
            helperText={errors[props.name] ? (errors[props.name]?.message as unknown as string) : ""}
          />
        )}
      />
    </>
  );
});

export default FormInput;
