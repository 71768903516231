import { Box, Grid, List, ListItemButton, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";
const styles = {
  navFontActive: { color: "black", fontWeight: "bold", fontSize: "15px" },
  navFont: {
    color: "white",
    width: "100%",
    paddingLeft: "5px",
    fontSize: "15px",
    fontWeight: "bold",
  },
  fontSubList: {
    fontSize: "15px",
    fontWeight: "bold",
  },
};
export default function ListSideBar({ props }: any) {
  const [open, setOpen] = useState(props.selected);
  const location = useLocation();
  useEffect(() => {
    if (props.child.find((item: any) => location.pathname.includes(item.path))) {
      handleSubList();
    }
    if (props.selected === true) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleListSidebar = () => {
    let item = {
      ...props,
      selected: !open,
    };
    props.handleListSidebar(item);
    setOpen(!open);
  };

  const handleSubList = () => {
    let item = {
      ...props,
      selected: true,
    };
    props.handleListSidebar(item);
    setOpen(true);
  };
  const selected = (item: any) => {
    const child = item.child.find((w: any) => location.pathname.includes(w.path));
    if (child) {
      return true;
    }
    return false;
  };
  return (
    <>
      {open ? (
        <>
          <Grid container>
            <Grid item xs={10}>
              <List
                sx={{
                  bgcolor: "black",
                  pl: 2,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="sidebar"
              >
                <ListItemButton
                  onClick={handleListSidebar}
                  sx={{
                    bgcolor: "white",
                    color: "black",
                    py: 2,
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                  className="sidebar-list"
                >
                  {props.name}
                </ListItemButton>
                {props.child.map((child: any, index: number) => (
                  <Link to={child.path} key={index} style={{ textDecoration: "none" }}>
                    <ListItemButton
                      onClick={handleSubList}
                      className={index + 1 === props.child.length ? `sidebar-sub-list` : ""}
                      sx={{
                        bgcolor: "white",
                        color: "black",
                      }}
                    >
                      <Grid container>
                        <Grid xs={2} item pt={"3px"} style={{ textAlign: "end" }}>
                          {location.pathname.includes(child.path) ? (
                            <>
                              <FiberManualRecordIcon sx={styles.fontSubList} color="primary"></FiberManualRecordIcon>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid xs={10} item sx={styles.fontSubList}>
                          {child.name}
                        </Grid>
                      </Grid>
                    </ListItemButton>
                  </Link>
                ))}
              </List>
            </Grid>
            <Grid item xs={"auto"}>
              <KeyboardArrowRightIcon
                onClick={() => handleListSidebar()}
                fontSize="large"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  mt: 1,
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <ListItemButton sx={{ py: 0 }} className="sidebar-selected" selected={selected(props)} onClick={() => handleListSidebar()}>
            {selected(props) ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    backgroundColor: "white",
                    height: "40px",
                    alignContent: "center",
                    pl: 1,
                    "& > :not(style)": {
                      // width: "100%",
                      // ml: "5px",
                      height: 25,
                      " :hover": {
                        bgcolor: red,
                      },
                    },

                    borderRadius: "5px",
                  }}
                >
                  <Paper elevation={0} component={Stack} direction="column" justifyContent="center">
                    <Typography style={styles.navFontActive}>{props.name}</Typography>
                  </Paper>
                </Box>
                <KeyboardArrowRightIcon
                  fontSize="large"
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                />
              </>
            ) : (
              <>
                <div style={styles.navFont}>{props.name}</div>
                <div
                  style={{
                    textAlign: "end",
                  }}
                >
                  <KeyboardArrowRightIcon
                    fontSize="large"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                      mt: 1,
                    }}
                  />
                </div>
              </>
            )}
          </ListItemButton>
        </>
      )}
    </>
  );
}
