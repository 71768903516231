import axios from "axios";

axios.defaults.headers.common["accept"] = "application/json";
axios.defaults.headers.common["authorization"] = `Bearer ${localStorage.getItem("cmt_token") || ""}`;

const httpService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_VERSION}`,
  headers: {
    accept: "application/json",
  },
});
httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ERR_NETWORK") return Promise.reject(error);
    error.message = error.response?.data.message ?? error.message;
    const errorStatus: number = error.response?.status;
    if (axios.isAxiosError(error) && error.response) {
      if (error.config.url !== undefined && errorStatus === 401) {
        window.location.href = "/admin/login";
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default httpService;
