import { useDispatch } from "react-redux";
import { setTitle } from "store/reducer/appReducer";
import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { editSuccess, somethingWrong } from "helpers/utility";
import { IModal } from "interfaces/utils/utils.interface";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { styles } from "assets/styles";
import { defaultModal } from "helpers/utility";
import FormInput from "components/FormInput";
import { policyService } from "services/user/policy.service";
import { errorMsg } from "helpers/helper";
const Schema = object({
  detailTh: string().min(1, "กรุณาระบุนโยบายความเป็นส่วนตัวภาษาไทย"),
  detailEn: string().min(1, "กรุณาระบุนโยบายความเป็นส่วนตัวภาษาอังกฤษ"),
});
type ISchema = TypeOf<typeof Schema>;

export default function Policy() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: ISchema = { detailTh: "", detailEn: "" };
  const methods = useForm<ISchema>({ resolver: zodResolver(Schema), defaultValues });
  const get = async () => {
    await policyService.get().then((item: any) => {
      const data = item.data.data ?? item.data;

      methods.setValue("detailTh", data.detailTh);
      methods.setValue("detailEn", data.detailEn);
    });
  };
  useEffect(() => {
    dispatch(setTitle("จัดการข้อมูลนโยบายความเป็นส่วนตัว"));
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmitHandler: SubmitHandler<ISchema> = async (values: ISchema) => {
    const data = {
      detailTh: values.detailTh,
      detailEn: values.detailEn,
    };
    setLoading(true);

    await policyService
      .update(data)
      .then(() => {
        setModal({ ...editSuccess });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          setModal({
            open: true,
            message: errorMsg(err),
            status: "error",
          });
        } else {
          setModal(somethingWrong);
        }
      });

    setLoading(false);
  };

  const form = (
    <>
      <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={2} pt={1}>
            <Typography className="label-input" sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              นโยบายความเป็นส่วนตัว
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5} pt={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">ภาษาไทย</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput placeholder="ระบุนโยบายความเป็นส่วนตัว" fullWidth multiline rows={8} size="small" name="detailTh" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={5}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2} pt={1}>
            <Typography className="label-input">ภาษาอังกฤษ</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <FormInput placeholder="Specify privacy policy" fullWidth multiline rows={8} size="small" name="detailEn" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" pt={2}>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit">
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      <FormProvider {...methods}>{form}</FormProvider>
    </>
  );
}
