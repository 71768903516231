import Policy from "./General/policy";
import ContactUs from "./General/contact_us";

import Banner from "./Banner/index";
import BannerForm from "./Banner/form";

import AboutUs from "./AboutUs/index";
import Careers from "./Careers/index";

import GoodsServices from "./GoodsServices/index";
import GoodsServicesForm from "./GoodsServices/form";

import Quality from "./Quality/index";
import QualityForm from "./Quality/form";

import Machine from "./Machine/index";
import MachineForm from "./Machine/form";

import Transport from "./Transport/index";
import TransportForm from "./Transport/form";

import SDGS from "./SDGS/index";
import SDGSForm from "./SDGS/form";

import Certificate from "./Certificate/index";
import CertificateForm from "./Certificate/form";

import Activity from "./Activity/index";
import ActivityForm from "./Activity/form";

import JobOpening from "./JobOpening/index";
import JobOpeningForm from "./JobOpening/form";

import JobList from "./JobList/index";
import JobListForm from "./JobList/form";

import Logo from "./Logo/index";
import LogoForm from "./Logo/form";
export const User = {
  Policy,
  ContactUs,
  Banner,
  BannerForm,
  Careers,
  AboutUs,
  GoodsServices,
  GoodsServicesForm,
  Quality,
  QualityForm,
  Machine,
  MachineForm,
  Transport,
  TransportForm,
  SDGS,
  SDGSForm,
  Certificate,
  CertificateForm,
  Activity,
  ActivityForm,
  Logo,
  LogoForm,
  JobOpening,
  JobOpeningForm,
  JobList,
  JobListForm,
};
