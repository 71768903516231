import { Button, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import IconView from "assets/icon/ic_view.png";
import { Link } from "react-router-dom";
import PageSize from "components/PageSize";
import Pagination from "components/Pagination";
import CustomTableCell from "components/CustomTableCell";
import CustomTableRow from "components/CustomTableRow";
import { styles } from "assets/styles";
import { setHeader } from "helpers/utility";
import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { setTitle } from "store/reducer/appReducer";
import Loading from "components/Loading";
import Select from "components/Select";
import { jobListService } from "services/user/jobList.service";
import { jobOpeningService } from "services/user/jobOpening.service";
import moment from "moment";
const headers = setHeader([
  { name: "ตำแหน่งงานที่สมัคร", pl: 5, width: "280px" },
  { name: "ชื่อ-นามสกุล", align: "center" },
  { name: "เบอร์โทรศัพท์", align: "center" },
  { name: "วันที่สมัคร", align: "center" },
  { name: "สถานะ", align: "center" },
  { name: "จัดการ", align: "center", width: "90px" },
]);
export default function JobList() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState("5");
  const [pageCount, setPageCount] = useState("0");
  const [dataTable, setDataTable] = useState<any>([]);

  const [jobPositionId, setJobPositionId] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  const ddlStatus = [
    {
      label: "รอดำเนินการ",
      value: "pending",
    },
    {
      label: "ดำเนินการแล้ว",
      value: "approved",
    },
  ];
  const [ddlJobTitle, setDatasourceJobTitle] = useState<any>([]);

  const handleChangePageSize = (event: SelectChangeEvent) => setPageSize(event.target.value as string);
  const handleChangePage = (value: any) => setPage(value);
  const search = async () => {
    await getDataSource();
    const data = {
      jobPositionId: jobPositionId,
      jobStatus: jobStatus,
      download: false,
    };
    await jobListService.get(data, String(page), pageSize).then((item: any) => {
      const data = item.data.data ?? item.data;
      if (item.data.count !== 0) {
        setPageCount(item.data.count || 1);
      }
      setDataTable(data);
    });
  };
  const getDataSource = async () => {
    await jobOpeningService.get("1", "9999").then((item: any) => {
      const data = item.data.data ?? item.data;
      const ddl = data.map((item: any) => {
        return {
          label: item.nameTh,
          value: item.id,
        };
      });
      setDatasourceJobTitle(ddl);
    });
  };
  useEffect(() => {
    dispatch(setTitle("ดูรายการสมัครงาน"));
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, jobStatus, jobPositionId]);

  const checkStatus = (item: any) => {
    let classes = "";
    let text = "";
    if (item.jobStatus === "pending") {
      classes = "text-wait";
      text = "รอดำเนินการ";
    } else if (item.jobStatus === "approved") {
      classes = "text-active";
      text = "ดำเนินการแล้ว";
    }
    return (
      <CustomTableCell align="center" className={classes}>
        {text}
      </CustomTableCell>
    );
  };
  const [loading, setLoading] = useState<boolean>(false);
  const exportExcel = async () => {
    setLoading(true);
    const data = {
      jobPositionId: jobPositionId,
      jobStatus: jobStatus,
      download: true,
    };
    await jobListService.downloadExcel(data, String(page), pageSize).then((response: any) => {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `job_position`);
      document.body.appendChild(link);
      link.click();
    });
    setLoading(false);
  };

  return (
    <>
      <Loading loading={loading} />

      <Grid container>
        <Grid container justifyContent="start" item xs={12} sm={12} md={12} lg={9} xl={10} spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={4} mb={2}>
            <Select
              className="select-icon-primary"
              fullWidth
              name="jobTitle"
              datasource={ddlJobTitle}
              value={jobPositionId}
              onChange={setJobPositionId}
              rounded
              placeholder="ตำแหน่งงานที่สมัคร"
              iconColor="primary"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={4} mb={2}>
            <Select
              className="select-icon-primary"
              fullWidth
              name="status"
              datasource={ddlStatus}
              value={jobStatus}
              onChange={setJobStatus}
              rounded
              placeholder="สถานะ"
              iconColor="primary"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2} mb="15px">
            <Button style={{ backgroundColor: "white" }} sx={styles.btnRoundOutline} variant="outlined" color="primary" onClick={() => search()}>
              ค้นหา
            </Button>
          </Grid>
        </Grid>

        <Grid container justifyContent="end" item xs={12} sm={12} md={4} lg={3} xl={2} mb="15px">
          <Button
            onClick={() => {
              exportExcel();
            }}
            sx={styles.btnRoundExport}
            variant="contained"
            type="submit"
            // startIcon={<SaveAltIcon></SaveAltIcon>}
          >
            ส่งออก Excel
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table sx={{ minWidth: 900 }} size="small">
          <TableHead className="table-header">
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>
            {dataTable.length > 0 ? (
              dataTable.map((row: any) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell align="left" sx={{ pl: 5 }}>{`${row.jobPosition?.nameTh}`}</CustomTableCell>
                  <CustomTableCell align="center">{`${row.firstname} ${row.lastname}`}</CustomTableCell>
                  <CustomTableCell align="center">{`${row.tel}`}</CustomTableCell>
                  <CustomTableCell align="center">{moment(row.createdAt).format("DD/MM/YYYY HH:mm") || ""}</CustomTableCell>

                  {checkStatus(row)}
                  <CustomTableCell align="center">
                    <Link to={`detail/${row.id}`}>
                      <Button variant="text" style={styles.btnAction}>
                        <img src={IconView} alt="action" width="100%" />
                      </Button>
                    </Link>
                  </CustomTableCell>
                </CustomTableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination count={parseInt(`${Math.ceil(parseInt(pageCount) / parseInt(pageSize))}`)} page={page} handleChange={handleChangePage} />
      <PageSize pageSize={pageSize} page={page} count={pageCount} handleChange={handleChangePageSize}></PageSize>
    </>
  );
}
