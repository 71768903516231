import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/job-position/all?page=${page}&pageSize=${pageSize}`);
  }

  public getById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/job-position/${id}`);
  }
  public create(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_position");
    const formData = new FormData();
    formData.append("nameTh", data.nameTh);
    formData.append("nameEn", data.nameEn);
    formData.append("detailTh", data.detailTh);
    formData.append("detailEn", data.detailEn);
    formData.append("qualificationTh", data.qualificationTh);
    formData.append("qualificationEn", data.qualificationEn);
    formData.append("welfareTh", data.welfareTh);
    formData.append("welfareEn", data.welfareEn);
    formData.append("amount", data.amount);
    formData.append("isActive", data.isActive);
    return httpService.post<Response>(`admin/job-position`, formData);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_position");
    const formData = new FormData();
    formData.append("nameTh", data.nameTh);
    formData.append("nameEn", data.nameEn);
    formData.append("detailTh", data.detailTh);
    formData.append("detailEn", data.detailEn);
    formData.append("qualificationTh", data.qualificationTh);
    formData.append("qualificationEn", data.qualificationEn);
    formData.append("welfareTh", data.welfareTh);
    formData.append("welfareEn", data.welfareEn);
    formData.append("amount", data.amount);
    formData.append("isActive", data.isActive);
    return httpService.patch<Response>(`admin/job-position/${data.id}`, formData);
  }
  public updateActive(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_position");
    return httpService.patch<Response>(`admin/job-position/active/${data.id}`, {
      isActive: data.isActive,
    });
  }
  public delete(id: string): Promise<AxiosResponse<Response>> {
    logService.create("manage_position");
    return httpService.delete<Response>(`admin/job-position/${id}`);
  }
}

export const jobOpeningService = new Services();
