import type { AxiosResponse } from "axios";
import httpService from "plugins/axios";
import { Response } from "interfaces/utils/utils.interface";
import { AdminInformation, AdminPosition } from "interfaces/admin/admin.interface";

class AdminService {
  public searchUser(keyword: string, page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    return httpService.post<Response>(`root/admin/search?page=${page}&pageSize=${pageSize}`, {
      keyword,
    });
  }
  public getUserById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`root/admin/${id}`);
  }
  public createUser(data: AdminInformation): Promise<AxiosResponse<Response>> {
    return httpService.post<Response>(`root/admin`, data);
  }
  public updateUser(data: AdminInformation): Promise<AxiosResponse<Response>> {
    return httpService.patch<Response>(`root/admin/${data.id}`, data);
  }
  public deleteUser(id: string): Promise<AxiosResponse<Response>> {
    return httpService.delete<Response>(`root/admin/${id}`);
  }

  public searchPosition(page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    return httpService.post<Response>(`root/position/search?page=${page}&pageSize=${pageSize}`);
  }
  public getPositionById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`root/position/${id}`);
  }
  public createPosition(data: AdminPosition): Promise<AxiosResponse<Response>> {
    return httpService.post<Response>(`root/position`, data);
  }
  public updatePosition(data: AdminPosition): Promise<AxiosResponse<Response>> {
    return httpService.put<Response>(`root/position `, data);
  }
  public deletePosition(id: string): Promise<AxiosResponse<Response>> {
    return httpService.delete<Response>(`root/position/${id}`);
  }

  public getAllPosition(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`root/position/all`);
  }
  public getAllSystemUses(): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`root/system-uses`);
  }

  public searchLogUser(log: any): Promise<AxiosResponse<Response>> {
    if (log.download === true) {
      return httpService.post<Response>(
        `root/admin-log/search?page=${log.page}&pageSize=${log.pageSize}`,
        {
          keyword: log.keyword,
          startDate: log.startDate,
          endDate: log.endDate,
          Download: true,
        },
        { responseType: "blob" }
      );
    } else {
      return httpService.post<Response>(`root/admin-log/search?page=${log.page}&pageSize=${log.pageSize}`, {
        keyword: log.keyword,
        startDate: log.startDate,
        endDate: log.endDate,
        Download: log.download || false,
      });
    }
  }
}

export const adminService = new AdminService();
