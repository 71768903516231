import Information from "./Information/index";
import InformationForm from "./Information/form";

import Role from "./Role/index";
import RoleForm from "./Role/form";

import Log from "./Log/index";

export const Admin = {
  Information,
  InformationForm,
  Log,
  Role,
  RoleForm,
};
