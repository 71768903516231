import type { AxiosResponse } from "axios";
import { Response } from "interfaces/utils/utils.interface";
import httpService from "plugins/axios";
import { logService } from "./log.service";

class Services {
  public get(data: any, page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    const formData = new FormData();
    if (data.jobPositionId) formData.append("jobPositionID", data.jobPositionId);
    if (data.jobStatus) formData.append("jobStatus", data.jobStatus);
    formData.append("download", data.download);
    return httpService.post<Response>(`admin/job-application/search?page=${page}&pageSize=${pageSize}`, formData);
  }
  public downloadExcel(data: any, page: string, pageSize: string): Promise<AxiosResponse<Response>> {
    const formData = new FormData();
    if (data.jobPositionId) formData.append("jobPositionID", data.jobPositionId);
    if (data.jobStatus) formData.append("jobStatus", data.jobStatus);
    formData.append("download", data.download);
    return httpService.post<Response>(`admin/job-application/search?page=${page}&pageSize=${pageSize}`, formData, { responseType: "blob" });
  }

  public getById(id: string): Promise<AxiosResponse<Response>> {
    return httpService.get<Response>(`admin/job-application/${id}`);
  }
  public update(data: any): Promise<AxiosResponse<Response>> {
    logService.create("manage_job_application");
    return httpService.patch<Response>(`admin/job-application/approve/${data.id}`);
  }
}

export const jobListService = new Services();
