import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTitle, selectCurrentUser, selectShowBtnBack, selectBackToPath, selectRootAdmin } from "store/reducer/appReducer";

import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Logo from "assets/logo/ic_bg_small@2x.png";
import { authService } from "services/authentication/auth.service";
import Modal from "components/DialogConfirm";
import { IModal } from "interfaces/utils/utils.interface";
import { Divider, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListSideBar from "./ListSideBar";
import { defaultModal } from "helpers/utility";

const drawerWidth = 300;
const drawerHeight = 80;
interface Props {
  listMenu?: any;
  window?: () => Window;
}

const styles = {
  btnBack: {
    backgroundColor: "white",
    color: "#0a52a4",
    width: "32px",
    height: "32px",
    minWidth: 0,
    minHeight: 0,
    mr: 2,
    "&:hover": {
      color: "white",
    },
  },
  navFont: {
    color: "white",
    fontWeight: "bold",
    fontSize: "15px",
    width: "100%",
    paddingLeft: "5px",
  },
  navFontActive: { color: "black", fontWeight: "bold", fontSize: "15px" },
  avatar: { bgcolor: "#0a52a4", mr: 2 },
  profile: {
    color: "black",
    fontSize: "20px",
    fontWeight: "bold",
    textTransform: "none",
    display: { xs: "none", sm: "block" },
  },
  profileMenu: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  profileIcon: {
    // fontSize: "24x",
    color: "#0a52a4",
    ml: 1,
    display: { xs: "none", sm: "block" },
  },
  hoverListProfile: {
    py: "10px",
    "&:hover": {
      color: "white",
      backgroundColor: "#0a52a4",
    },
  },
  main: {
    flexGrow: 1,
    p: 3,
    paddingTop: 5,
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    minHeight: "100vh",
    backgroundColor: "white",
  },
};
export function getWindowDimensions() {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  return {
    windowWidth,
    windowHeight,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowDimensions;
}
export default function ResponsiveDrawer(props: Props) {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listMenu]);
  const { windowHeight } = useWindowDimensions();
  const isRootAdmin = useSelector(selectRootAdmin);
  let showScrollSidebar = "hidden";
  if (isRootAdmin === false) {
    if (windowHeight > 860) {
      const element = document.querySelector(
        ".MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation2.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft"
      );
      if (element) {
        element.scrollTop = 0;
      }
    }
    showScrollSidebar = windowHeight > 860 ? "hidden" : "auto";
  }

  const [modal, setModal] = useState<IModal>(defaultModal);
  const navigate = useNavigate();
  const title = useSelector(selectTitle);
  const isShowBtnback = useSelector(selectShowBtnBack);
  const backTo = useSelector(selectBackToPath);
  const currentUser = useSelector(selectCurrentUser);
  const { window } = props;
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const location = useLocation();
  const handleDrawerToggle = () => {
    setSideBarOpen(!sideBarOpen);
  };
  const configModal = () => {
    setModal({
      ...modal,
      open: true,
      message: "คุณต้องการออกจากระบบใช่หรือไม่ ?",
      status: "warning",
    });
  };

  const logout = async (status: any) => {
    if (status) {
      setModal({
        ...modal,
        open: false,
      });
      await authService.logout(isRootAdmin);
      navigate("/");
      navigate(0);
    } else {
      setModal({
        ...modal,
        open: false,
      });
    }
  };
  const handleListSidebar = (item: any) => {
    props.listMenu.map((element: any) => {
      if (element.path !== item.path) element.selected = false;
      else element.selected = item.selected;
      return element;
    });
  };
  const clearSublist = () => {
    props.listMenu.map((element: any) => {
      element.selected = false;
      return element;
    });
  };

  const drawer = (
    <div>
      <Box
        sx={{
          width: drawerWidth,
          height: drawerHeight + 20,
          textAlign: "center",
          paddingTop: "15px",
          bgcolor: "black",
        }}
      ></Box>

      <Box
        sx={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "space-between",
          px: 0,
          mx: 0,
          bgcolor: "black",
        }}
      >
        <List>
          {props.listMenu.map((item: any, idx: number) => (
            <div key={idx}>
              {item.child ? (
                <>
                  <ListSideBar
                    props={{
                      ...item,
                      handleListSidebar,
                    }}
                  ></ListSideBar>
                </>
              ) : (
                <Link key={idx} to={item.path} style={{ textDecoration: "none" }} onClick={clearSublist}>
                  <ListItem key={idx} disablePadding>
                    <ListItemButton sx={{ py: 0 }} selected={location.pathname.includes(item.path)}>
                      {location.pathname.includes(item.path) ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              "& > :not(style)": {
                                m: 1,
                                height: 25,
                              },
                              borderRadius: "5px",
                            }}
                          >
                            <Paper elevation={0} component={Stack} direction="column" justifyContent="center">
                              <Typography style={styles.navFontActive}>{item.name}</Typography>
                            </Paper>
                          </Box>
                          <KeyboardArrowRightIcon
                            fontSize="large"
                            sx={{
                              fontWeight: "bold",
                              color: "white",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <div style={styles.navFont}>{item.name}</div>
                          <div
                            style={{
                              textAlign: "end",
                            }}
                          >
                            <KeyboardArrowRightIcon
                              fontSize="large"
                              sx={{
                                fontWeight: "bold",
                                color: "white",
                                mt: 1,
                              }}
                            />
                          </div>
                        </>
                      )}
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}
            </div>
          ))}
        </List>
      </Box>
    </div>
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: logout,
        }}
      />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            bgcolor: "background.paper",
            color: "black",
            height: drawerHeight,
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar style={{ paddingLeft: 0 }}>
            <Box
              sx={{
                width: drawerWidth,
                height: drawerHeight,
                textAlign: "center",
                paddingTop: "10px",
                display: { xs: "none", sm: "block" },
              }}
            >
              <div style={{ paddingBottom: 0 }}>
                <img src={Logo} width={40} height={40} alt="logo" />
              </div>
              <div
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Central Metals (Thailand) Ltd.
              </div>
            </Box>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ ml: 2, display: { sm: "none" } }}>
              <MenuIcon />
            </IconButton>
            <Typography noWrap component="div" ml={1} sx={{ fontSize: "28px", pl: 3 }}>
              {isShowBtnback ? (
                <Link to={backTo} style={{ textDecoration: "none", color: "black" }}>
                  <Button size="small" variant="contained" className="nav-back-btn" sx={styles.btnBack}>
                    <ArrowBackIcon sx={{ fontSize: 22, fontWeight: "bold" }}></ArrowBackIcon>
                  </Button>
                </Link>
              ) : (
                <></>
              )}
              {title}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box
              sx={{
                flexGrow: 0,
                height: drawerHeight,
                display: "flex",
              }}
            >
              <Tooltip title="Profile settings">
                <Button onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={styles.avatar} alt={currentUser} src="/static/images/avatar/2.jpg" />
                  <Typography component={"p"} sx={styles.profile}>
                    {currentUser}
                  </Typography>
                  <KeyboardArrowDownIcon fontSize="large" sx={styles.profileIcon} />
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: "45px", borderRadius: 0 }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                className="no-padding-list-profile"
              >
                <Link to="/profile" style={{ textDecoration: "none", color: "black" }}>
                  <MenuItem className="menuHover" onClick={handleCloseUserMenu} sx={styles.hoverListProfile}>
                    <Typography textAlign="center" width={200} sx={styles.profileMenu}>
                      <b>ข้อมูลส่วนตัว</b>
                    </Typography>
                  </MenuItem>
                </Link>
                <Divider />
                <Link to="/change-password" style={{ textDecoration: "none", color: "black" }}>
                  <MenuItem className="menuHover" onClick={handleCloseUserMenu} sx={styles.hoverListProfile}>
                    <Typography textAlign="center" width={200} sx={styles.profileMenu}>
                      <b>เปลี่ยนรหัสผ่าน</b>
                    </Typography>
                  </MenuItem>
                </Link>
                <Divider />
                <MenuItem className="menuHover" onClick={configModal} sx={styles.hoverListProfile}>
                  <Typography textAlign="center" width={200} sx={styles.profileMenu}>
                    <b>ออกจากระบบ</b>
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            backgroundColor: "black",
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={sideBarOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                overflow: "hidden",
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            PaperProps={{
              elevation: 2,
              style: {
                backgroundColor: "black",
                // boxShadow: "none",
              },
            }}
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                overflowY: showScrollSidebar,
                overflowX: "hidden",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main" sx={styles.main}>
          <Toolbar />
          <Outlet></Outlet>
        </Box>
      </Box>
    </>
  );
}
