import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { setTitleWithBack } from "store/reducer/appReducer";
import { Link, useLocation, useParams } from "react-router-dom";
import { errorMsg } from "helpers/helper";
import Select from "components/Select";
import { Button, FormControl, Grid, IconButton, InputAdornment, Typography } from "@mui/material";

import FormInput from "components/FormInput";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "components/Dialog";
import Loading from "components/Loading";
import { IDatasource, IModal } from "interfaces/utils/utils.interface";
import { adminService } from "services/admin/admin.service";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styles } from "assets/styles";
import { defaultModal, editSuccess, saveSuccess, somethingWrong } from "helpers/utility";
import { AdminInformation, AdminPosition } from "interfaces/admin/admin.interface";
const Schema = object({
  id: string(),
  firstname: string().min(1, "กรุณาระบุชื่อ"),
  lastname: string().min(1, "กรุณาระบุนามสกุล"),
  tel: string()
    .regex(/^[0-9]*$/, "กรุณาระบุหมายเลขโทรศัพท์เป็นตัวเลขเท่านั้น")
    .min(10, "กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว")
    .max(10, "กรุณาระบุเบอร์โทรศัพท์ไม่เกิน 10 ตัว"),
  email: string().min(1, "กรุณาระบุอีเมล").email("รูปแบบอีเมลไม่ถูกต้อง"),
  password: string()
    .min(1, "กรุณาระบุรหัสผ่าน")
    .min(6, "กรุณาระบุรหัสผ่านอย่างน้อย 6 ตัวอักษร")
    .regex(/^[A-Za-z0-9]*$/, "กรุณาระบุรหัสผ่านเป็นตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น"),
});
const SchemaEdit = object({
  id: string(),
  firstname: string().min(1, "กรุณาระบุชื่อ"),
  lastname: string().min(1, "กรุณาระบุนามสกุล"),
  tel: string()
    .regex(/^[0-9]*$/, "กรุณาระบุหมายเลขโทรศัพท์เป็นตัวเลขเท่านั้น")
    .min(10, "กรุณาระบุเบอร์โทรศัพท์ให้ครบทั้ง 10 ตัว")
    .max(10, "กรุณาระบุเบอร์โทรศัพท์ไม่เกิน 10 ตัว"),
  email: string().min(1, "กรุณาระบุอีเมล").email("รูปแบบอีเมลไม่ถูกต้อง"),
});
type IUser = TypeOf<typeof Schema>;

export default function UserManagement() {
  const backToPath = "/admin/information";
  const dispatch = useDispatch();
  const location = useLocation();
  const isCreate = location.pathname.search("create") !== -1;
  const params = useParams();

  const [role, setRole] = useState("");
  const [validateRole, setValidateRole] = useState(false);

  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState<boolean>(false);
  const [ddlRoleDatasource, setDDLRoleDatasource] = useState<IDatasource[]>([]);
  const defaultValues: IUser = {
    id: "####",
    firstname: "",
    lastname: "",
    tel: "",
    email: "",
    password: "",
  };
  const methods = useForm<IUser>({
    resolver: zodResolver(Schema),
    defaultValues,
  });
  const methodsEdit = useForm<IUser>({
    resolver: zodResolver(SchemaEdit),
    defaultValues,
  });
  const getDDLPosition = async () => {
    await adminService.getAllPosition().then((item: any) => {
      const data = item.data.data ?? item.data;
      const ddl = data.map((item: AdminPosition) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setDDLRoleDatasource(ddl);
    });
  };
  const getUserById = async () => {
    await adminService.getUserById(String(params.id)).then((item: any) => {
      const data = item.data.data ?? item.data;
      setRole(data.adminPosition?.id || "");
      setMethodValues(data);
    });
  };
  const setMethodValues = (value: any) => {
    methodsEdit.setValue("id", String(value.id).padStart(4, "0"));
    methodsEdit.setValue("firstname", value.firstname);
    methodsEdit.setValue("lastname", value.lastname);
    methodsEdit.setValue("tel", value.tel);
    methodsEdit.setValue("email", value.email);
    methodsEdit.setValue("password", value.password || "");
  };
  useEffect(() => {
    getDDLPosition();
    if (isCreate) {
      dispatch(
        setTitleWithBack({
          title: "เพิ่มผู้ดูแลระบบ",
          path: backToPath,
        })
      );
    } else {
      dispatch(
        setTitleWithBack({
          title: "แก้ไขข้อมูลผู้ดูแลระบบ",
          path: backToPath,
        })
      );
      getUserById();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler: SubmitHandler<IUser> = async (values: IUser) => {
    setLoading(true);
    const entity: AdminInformation = {
      id: parseInt(params.id || ""),
      firstname: values.firstname,
      lastname: values.lastname,
      tel: values.tel,
      email: values.email,
      password: values.password,
      adminPositionId: role,
    };
    if (isCreate) {
      await adminService
        .createUser(entity)
        .then(() => {
          setModal({ ...saveSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
      setLoading(false);
    } else {
      await adminService
        .updateUser(entity)
        .then(() => {
          setModal({ ...editSuccess, navigateTo: backToPath });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            setModal({
              open: true,
              message: errorMsg(err),
              status: "error",
            });
          } else {
            setModal(somethingWrong);
          }
        });
      setLoading(false);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSelect = (value: any) => {
    setRole(value);
    setValidateRole(false);
  };
  const handleValid = () => {
    if (!role) setValidateRole(true);
    else {
      setValidateRole(false);
    }
  };
  const form = (
    <>
      <form onSubmit={isCreate ? methods.handleSubmit(onSubmitHandler) : methodsEdit.handleSubmit(onSubmitHandler)}>
        <Grid container justifyContent="start" alignItems="start" pl={3} pt={5}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">ID</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormInput
              className="input-disabled"
              fullWidth
              size="small"
              name="id"
              disabled
              sx={{
                mb: "1.5rem",
                "& fieldset": { border: "none" },
                borderRadius: "5px",
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={3}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">ชื่อ</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormInput fullWidth size="small" name="firstname" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>{" "}
        <Grid container justifyContent="start" alignItems="start" pl={3}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">นามสกุล</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormInput fullWidth size="small" name="lastname" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>{" "}
        <Grid container justifyContent="start" alignItems="start" pl={3}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">เบอร์โทรศัพท์</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormInput fullWidth size="small" name="tel" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={3} mb={"1.5rem"}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">ตำแหน่ง</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select fullWidth name="role" nameth={`ตำแหน่ง`} datasource={ddlRoleDatasource} value={role} onChange={handleSelect} validate={validateRole} />
          </Grid>
        </Grid>
        <Grid container justifyContent="start" alignItems="start" pl={3}>
          <Grid item xs={3} pt={1}>
            <Typography className="label-input">{`อีเมล (สำหรับ Login)`}</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormInput fullWidth size="small" name="email" sx={{ mb: "1.5rem" }} />
          </Grid>
        </Grid>
        {isCreate ? (
          <>
            <Grid container justifyContent="start" alignItems="start" pl={3}>
              <Grid item xs={3} pt={1}>
                <Typography className="label-input">รหัสผ่าน</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth variant="outlined">
                  <FormInput
                    sx={{ mb: "1.5rem" }}
                    fullWidth
                    size="small"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid container justifyContent="center" my={2}>
          <Link to={backToPath} style={{ textDecoration: "none" }}>
            <Button className="btn-cancle" sx={styles.btnFormCancle} variant="outlined">
              ยกเลิก
            </Button>
          </Link>
          <Button sx={styles.btnFormConfirm} variant="contained" color="primary" type="submit" onClick={() => handleValid()}>
            บันทึก
          </Button>
        </Grid>
      </form>
    </>
  );
  return (
    <>
      <Loading loading={loading} />
      <Modal
        props={{
          modalOpen: modal.open,
          modalStatus: modal.status,
          modalMessage: modal.message,
          handleModal: setModal,
          navigateTo: modal.navigateTo,
        }}
      />
      {isCreate ? <FormProvider {...methods}>{form}</FormProvider> : <FormProvider {...methodsEdit}>{form}</FormProvider>}
    </>
  );
}
